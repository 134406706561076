import { CircularProgress } from '@material-ui/core';
import React, { Component } from 'react'

const vatom = new window.VatomicFace.LocalVatom();

export default class Share extends Component {
    constructor(){
        super()
        this.state={

        }
    }

    componentDidMount(){
        vatom.initVatom().then(async () => {
            await this.setState({ vatom: vatom })
        })
    }
    render() {
        return (
            <div style={{flex:1, backgroundColor: 'black', minHeight:"100vh", minWidth:"100vw", display:"flex", alignItems:'center', justifyContent:"center"}}>
                {this.state.vatom?
                <div style={{flex:1, display:'flex', flexDirection:'row', padding: 30}}>
                    <img src={this.state.vatom.resources.FullImage} style={{maxHeight: "70vh", objectFit: "contain", flex:1}}/>
                    {this.state.vatom.properties.required_cloning_score > this.state.vatom.properties.num_direct_clones ?
                    <div style={{color:'white', flex:1, alignItems:'center', justifyContent:'center', display:'flex'}}>
                        Comparte el sabor de esta Pepsi® con {this.state.vatom.properties.required_cloning_score - this.state.vatom.properties.num_direct_clones < 2 ? "la persona que más quieres" : `las ${this.state.vatom.properties.required_cloning_score - this.state.vatom.properties.num_direct_clones} personas que más quieres`}
                    </div>
                    :
                    <div style={{color:'white', flex:1, alignItems:'center', justifyContent:'center', display:'flex', flexDirection:'column'}}>
                        Ya puedes disfrutar de su gran sabor<br></br>Escanea tu código y di #SíConPepsi
                        <img src={"https://api.qrserver.com/v1/create-qr-code/?size=256x256&data=" + this.state.vatom.identifier} style={{marginTop:30}}/>
                    </div>
                    }
                </div>    
                :
                <CircularProgress style={{color:"white"}}/>
            }
            </div>
        )
    }
}
