import React, { Component } from 'react';
import Slider from "react-slick";
import './style/ExaTips.css'
import BlockVFaceApi from '@blockv/faces'


import Slide1 from './Slide1'
import Slide2 from './Slide2'

const vatom = new window.VatomicFace.LocalVatom()
export default class ExaTips extends Component {
    constructor() {
        super()
        this.state = {
            today: new Date().getTime(),
            // vatom: {
            //     identifier: "Sebas es un fregón",
            //     properties: {
            //         start_clue: "2018-11-11T14:10",
            //         end_clue: "2018-11-11T15:00",
            //         clue_type: "",
            //         clue_url: "",
            //         more_info: "https://mvsradio.com/concierto-exa-2018/",
            //         description:"Con esta pista podrás obtener tu boleto doble para el concierto EXA 2018 el 24 de noviembre.",
            //         done_text: "Busca más pistas regadas por la CDMX esta semana"
            //     },
            //     resources: {
            //         CardImage1: 'http://armadadigital.mx/tips_card.png',
            //         CardImage2: 'http://armadadigital.mx/exa_cartel.png'
            //     }
            // }
        }
        vatom.initVatom().then((res) => {
            console.log(vatom)
            this.setState({ vatom: vatom, user: res.data.user })
        })
    }

    componentWillUnmount(){
        window.removeEventListener('touchstart', this.touchStart);
        window.removeEventListener('touchmove', this.preventTouch, {passive: false});
    }
    
    touchStart(e){
        this.firstClientX = e.touches[0].clientX;
        this.firstClientY = e.touches[0].clientY;
    }
    
    preventTouch(e){
        const minValue = 5; // threshold
    
        this.clientX = e.touches[0].clientX - this.firstClientX;
        this.clientY = e.touches[0].clientY - this.firstClientY;
    
        if(Math.abs(this.clientY)>0){
            e.preventDefault();
            e.returnValue = false;
            return false; 
        }
        // Vertical scrolling does not work when you start swiping horizontally.
        if(Math.abs(this.clientX) > minValue){ 
            e.preventDefault();
            e.returnValue = false;
            return false;
        }
    }

    componentDidMount() {
        window.addEventListener('touchstart', this.touchStart);
        window.addEventListener('touchmove', this.preventTouch, {passive: false});


        setInterval(() => {
            var today = new Date().getTime()
            var start_date = new Date(this.state.vatom.properties.start_clue+"-06:00").getTime()
            var end_date = new Date(this.state.vatom.properties.end_clue+"-06:00").getTime()
            this.setState({ start_date: start_date, end_date: end_date, today: today })
        }, 1000)
        fetch("https://graph.facebook.com/v3.2/140038262702805?fields=live_videos&access_token=EAAC4SRiGZAUYBAB4vz8uc4FjKr0wn6vb5N82loZCczdN5LMZCIZCifTzJ1rtiMWyb6kDBgm23HE6F47nEp7imh5C51LZCLPZB4VUJxRqB4PFYnqeBXQaM56Dowog68ZAcnHdQDPCRMQ8QvBYf9mlg4kJi8x9FthJRYZD")
            .then(result => {
                return result.json()
            })
            .then(json => {
                console.log(json)
                this.setState({ videos: json.live_videos.data })
            })
        fetch("https://graph.facebook.com/v3.2/2382727361756404?fields=live_videos&access_token=EAAC4SRiGZAUYBAB4vz8uc4FjKr0wn6vb5N82loZCczdN5LMZCIZCifTzJ1rtiMWyb6kDBgm23HE6F47nEp7imh5C51LZCLPZB4VUJxRqB4PFYnqeBXQaM56Dowog68ZAcnHdQDPCRMQ8QvBYf9mlg4kJi8x9FthJRYZD")
            .then(result => {
                return result.json()
            })
            .then(json => {
                console.log(json)
            })

    }

    render() {
        const settings = {
            dots: true,
            infinite: false,
            arrows: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            // this.state.videos ?
            //     <div dangerouslySetInnerHTML={{ __html: this.state.videos[0].embed_html.replace("&width=1280","&width=1280&mute=0") }}></div>
            // : null
            this.state.today > this.state.start_date && this.state.today < this.state.end_date ?
                // <div style={{width:"100vw", height:"100vh", background:'#000', position:"relative"}} dangerouslySetInnerHTML={{ __html: `<iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2F2382727361756404%2Fvideos%2F318081842117617%2F&show_text=0&width=560&mute=0" width="560" height="315" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allowFullScreen="true" autoplay="true"></iframe>` }}></div>
                <div style={{width:"100vw", height:"100vh", background:'#000', position:"relative"}} dangerouslySetInnerHTML={{ __html: `<div style=position:relative;padding-bottom:56.25%;padding-top:30px;height:0;overflow:hidden;"><iframe style="width:100%;height:100%;position:absolute;left:0px;top:0px;overflow:hidden" frameborder="0" type="text/html" src="https://www.dailymotion.com/embed/video/x6x0z6j?autoplay=1" width="100%" height="100%"   allow="autoplay" allowfullscreen></iframe></div>` }}></div>
                // <div style={{width:"100vw", height:"100vh", background:'#000', position:"relative"}} dangerouslySetInnerHTML={{ __html: this.state.videos[0].embed_html.replace("&width","&mute=0&") }}></div>
                
                :
                <Slider {...settings} adaptiveHeight={true}>
                    <Slide1 vatom={this.state.vatom} />
                    <Slide2 vatom={this.state.vatom} />
                </Slider>

        )
    }
}