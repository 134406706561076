import React, { Component } from 'react'
import { CircularProgress } from '@material-ui/core'
import Slider from "react-slick";
import './Polygon.css'

const vatom = new window.VatomicFace.LocalVatom();

export default class PolygonChristmasContest extends Component {
    constructor() {
        super()
        this.state = {
            // vatom: {
            //     identifier: "Es una prueba",
            //     resources: {
            //         CardImage: 'https://cdn.blockv.io/blockv/publisher/blockv.vticketmx/polygon_christmas_card.png?Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYmxvY2t2LmlvLyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE1Nzc5MTQxNDZ9LCJEb21haW4iOiJodHRwczovL2Nkbi5ibG9ja3YuaW8ifX1dfQ__&Signature=DvqS3rfxJC87LKKEdBi38zlgxfxwfI2abtVuccAlJRI7yZCmbmstIj8ossv-v7Q13tpc1yAmAXOFMOSbcR3LN0wmqKkSc69fBXExANTvw694NebqpevpextcKBYqRkX2ftLl4JEb2tBRultJ7zeqPjxX4IH0wcYgzitHZq3HzVU4W8~xx9Rn8QEN9Iukvz4jBAqMPctvks5KmE1ufyoyQ8v3I8D2SaPT8NwAjJ6X8fcku1FlDoOah7Dgu4CzZeq8hGhkZ5b9lymVZjyhHJwRCpq0ye6VQvYGiNfgAFHYaouxdNs4mGiukQYSZU2N6YEwKs0TXqI~wENlUrOWcFZE6A__&Key-Pair-Id=APKAI3WYOGPWWCDCS6PA',
            //         Background: 'https://cdn.blockv.io/blockv/publisher/blockv.vticketmx/polygon_christmas_back.png?Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYmxvY2t2LmlvLyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE1Nzc5MTQxNDZ9LCJEb21haW4iOiJodHRwczovL2Nkbi5ibG9ja3YuaW8ifX1dfQ__&Signature=DvqS3rfxJC87LKKEdBi38zlgxfxwfI2abtVuccAlJRI7yZCmbmstIj8ossv-v7Q13tpc1yAmAXOFMOSbcR3LN0wmqKkSc69fBXExANTvw694NebqpevpextcKBYqRkX2ftLl4JEb2tBRultJ7zeqPjxX4IH0wcYgzitHZq3HzVU4W8~xx9Rn8QEN9Iukvz4jBAqMPctvks5KmE1ufyoyQ8v3I8D2SaPT8NwAjJ6X8fcku1FlDoOah7Dgu4CzZeq8hGhkZ5b9lymVZjyhHJwRCpq0ye6VQvYGiNfgAFHYaouxdNs4mGiukQYSZU2N6YEwKs0TXqI~wENlUrOWcFZE6A__&Key-Pair-Id=APKAI3WYOGPWWCDCS6PA',
            //     },
            //     properties: {
            //         card: "AS",
            //         participant_number: "10"
            //     }
            // },
            // user: {
            //     displayName: "Sebastian Sanchez Alcala"
            // }
        }
    }

    componentDidMount() {
        vatom.initVatom().then((res) => {
            if (!vatom.properties.card) {
                fetch("https://api.jalo.app/vatoms/polygon/christmas", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ vatom_id: vatom.identifier })
                }).then(res => {
                    return res.json()
                }).then(async result => {
                    console.log(result)
                    var payload = {
                        "actionName": "Update",
                        "actionData": {
                            "this.id": vatom.identifier,
                            "properties": {
                                "card": result.data.card,
                                "participant_number": result.data.id
                            }
                        }
                    }

                    vatom.properties.card = result.data.card;
                    vatom.properties.participant_number = result.data.id;

                    await window.VatomicFace.VatomicBridge.sendRequest("vatom.performAction", payload)
                    this.setState({ vatom: vatom, user: res.data.user })
                })
            }
            else {
                this.setState({ vatom: vatom, user: res.data.user })
            }

        })
    }


    componentWillUnmount() {
        window.removeEventListener('touchstart', this.touchStart);
        window.removeEventListener('touchmove', this.preventTouch, { passive: false });
    }

    touchStart(e) {
        this.firstClientX = e.touches[0].clientX;
        this.firstClientY = e.touches[0].clientY;
    }

    preventTouch(e) {
        const minValue = 5; // threshold

        this.clientX = e.touches[0].clientX - this.firstClientX;
        this.clientY = e.touches[0].clientY - this.firstClientY;

        // Vertical scrolling does not work when you start swiping horizontally.
        if (Math.abs(this.clientX) > minValue) {
            e.preventDefault();
            e.returnValue = false;
            return false;
        }
    }


    render() {
        const settings = {
            dots: true,
            infinite: false,
            arrows: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            this.state.vatom ?
                <div style={{ height: "100vh", width: "100vw", backgroundColor: "#f7f7f7"}}>
                    <Slider {...settings} adaptiveHeight={true}>
                        <div>
                            <div style={{ width: "100vw", height: "100vh", textAlign: "center" }}>
                                <img src={this.state.vatom.resources.CardImage} style={{ width: "100%", height: "100%" }} />
                            </div>
                        </div>
                        <div>
                            <div style={{
                                height: "100vh", width: "100vw", margin: 0, display: "inline-block", textAlign: "center", position: "relative", backgroundPosition: "center",
                                backgroundImage: `url(${this.state.vatom.resources.Background})`, 
                                backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundColor: "#f7f7f7"
                            }}>
                                 <p style={{fontFamily:"TCMI", fontStyle:"normal", fontSize: "4.5vh", marginTop:"11vh"}} >
                                    ESTA CARTA <br></br> TE PERMITIRÁ
                                    <br></br>
                                    <span style={{fontSize:"3vh"}}>PARTICIPAR EN LAS DINÁMICAS<br></br> DEL EVENTO</span>
                                </p>
                                <p style={{fontFamily:"TCMI", fontSize: "3.5vh"}} >
                                    {this.state.user.displayName}
                                </p>
                                <img style={{height:"45vh", display: "inline-block"}} src={require(`../../../../resources/polygon/christmas/${this.state.vatom.properties.card}.png`)} />
                                <p style={{fontFamily:"TCMI", fontSize: "3.5vh"}}>
                                    Número de participante: {this.state.vatom.properties.participant_number}
                                </p>

                            </div>
                        </div>
                    </Slider>
                </div>
                :
                <div style={{ position: "relative", height: "100vh", width: "100vw", textAlign: "center", fontSize: 30 }}>
                    <CircularProgress style={{ color: "black", position: "absolute", margin: "auto", top: 0, bottom: 0, left: 0, right: 0 }} />
                </div>
        )
    }
}