import React, { Component } from 'react';
import BlockVFaceApi from '@blockv/faces';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';


const vatom = new window.VatomicFace.LocalVatom()
export default class OxxoTest extends Component {
    constructor() {
        super();
        this.state = {
            showModal: false,
            showCode: false,
            vatom: {
                identifier: "Sebas",
                properties: {
                    action: "Toca para ver código",
                    title: "Coca Cola 600ml",
                    template_variation: "Oxxo",
                    date_redeemed: ""
                }
            }
        }
        vatom.initVatom().then((res) => {
            console.log(vatom)
            this.setState({ vatom: vatom, user: res.data.user })
        })
    }

    componentDidMount() {
        console.log(this.state.vatom)
    }

    handleCodeToggle() {
        this.setState({ showCode: !this.state.showCode })
    }

    async handleActivation() {
        var vatom = this.state.vatom
        var payload = {
            "actionName": "Update",
            "actionData": {
                "this.id": this.state.vatom.identifier,
                "properties": {
                    "date_redeemed": new Date().toISOString()
                }
            }
        }
        await window.VatomicFace.VatomicBridge.sendRequest("vatom.performAction", payload)
        vatom.properties.date_redeemed = new Date().toISOString()
        this.setState({ vatom: vatom, showModal: false })
    }

    render() {
        var vatomImage = vatom.resources.CardImage1 || require("../../resources/oxxo/coca.png")
        const qr = <img alt="premio" style={{ height: "30vh" }} src={"https://api.qrserver.com/v1/create-qr-code/?size=256x256&data=redeem://" + this.state.vatom.identifier} />
        return (
            <div style={{ height: "100vh", width: "100vw", margin: 0, display: "inline-block", textAlign: "center", position: "relative", }}>
                <div style={{ padding: "5%", height: "100%" }}>
                    <h1 style={{ fontFamily: "Montserrat" }}>¡Muchas gracias!</h1>
                    <h2 style={{ fontWeight: "light", fontFamily: "Montserrat" }}>¡Ganaste {this.state.vatom.properties.title}!</h2>
                    {!this.state.showCode ?
                        <img alt="qr codigo" style={{ height: "30vh", maxWidth: "80vw", objectFit: "contain" }} src={vatomImage} />
                        :
                        this.state.vatom.properties.template_variation.indexOf("Oxxo") === -1 ?
                            qr
                            :
                            <div style={{ height: "30vh", display: "table", width: "100%" }}>
                                <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                                    <p>Tu día gratis de Oxxo Cel termina el día {new Date(this.state.vatom.properties.date_redeemed).toLocaleString().split(" ")[0]}</p>
                                </div>
                            </div>
                    }
                    {typeof (this.state.vatom.properties.date_redeemed) == 'undefined' || this.state.vatom.properties.date_redeemed !== "" ?
                        <a style={{ display: "block", marginTop: "2%", cursor: "pointer", color: "#4A90E2", fontFamily: "Montserrat" }} onClick={this.handleCodeToggle.bind(this)}>{this.state.showCode ? "Toca para ver tu premio" : this.state.vatom.properties.action}</a>
                        : null}
                    <p style={{ fontWeight: 'lighter', color: '#919191', fontSize: "3vh", fontFamily: "Montserrat" }}>Disfruta tu premio y continúa participando para ganar más</p>
                    {this.state.vatom.properties.template_variation.indexOf('Oxxo') !== -1 && this.state.vatom.properties.date_redeemed == "" ?
                        <Button variant="contained" style={{ backgroundColor: "#4A90E2", textTransform: "inherit", borderRadius: 40, color: "#fff", padding: "5px 30px" }} onClick={() => this.setState({ showModal: true })}>Activar ahora</Button>
                        : null}
                </div>
                <Dialog
                    open={this.state.showModal}
                    style={{ textAlign: "center", padding: "1%" }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogTitle id="alert-dialog-title">¿Estás seguro?</DialogTitle>
                        <DialogContentText id="alert-dialog-description" style={{ fontWeight: 300 }}>
                            Tu día de OXXO CEL comenzará hoy y terminará mañana a la misma hora que lo activaste
                        </DialogContentText>
                    </DialogContent>
                    <Button variant="contained" style={{ color: "#fff", backgroundColor: "#4A90E2", borderRadius: 40, textTransform: "inherit", width: "70%", margin: "0 auto" }} onClick={this.handleActivation.bind(this)} autoFocus>
                        ¡Si!, activar
                        </Button>
                    <Button style={{ color: "#4A90E2", textTransform: "inherit", width: "70%", margin: "10px auto 20px auto" }} onClick={() => { this.setState({ showModal: false }) }} autoFocus>
                        Mejor en otro momento
                        </Button>
                </Dialog>
            </div>
        )
    }
}