import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class Slide1 extends Component {
    constructor() {
        super();
        this.state = {
            showModal: false
        }
    }
    componentWillMount() {
        var x = setInterval(() => {
            var today = new Date().getTime()
            
            var due_date = new Date(this.props.vatom.properties.event_date +"T"+ this.props.vatom.properties.event_time + "-06:00").getTime()
            var distance = due_date - today
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // console.log(due_date);
            // console.log(days);
            // console.log(hours);
            // console.log(minutes);
            // console.log(seconds);
            if (distance < 0) {
                clearInterval(x)
                this.setState({ distance: "terminó" })
            }
            else {
                this.setState({ distance: { days: days, hours: hours, minutes: minutes, seconds: seconds } })
            }
        }, 1000)
    }

    countdown() {

    }

    render() {
        return (
            <div style={{ height: "100vh", width: "100vw", backgroundImage: `url(${this.props.vatom.resources.CardImage || ""})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", margin: 0, display: "inline-block", textAlign: "center", position: "relative" }}>
                {this.state.distance && this.state.distance !=="terminó" ?
                    <div style={{ padding: "0 5%", fontSize: "4vh", marginTop: "40vh" }}>
                        {this.props.vatom.properties.template.indexOf("Child") > -1 ?
                            <p>{this.props.vatom.properties.owner.name} te invitó a {this.props.vatom.title}</p>
                            : null}
                        Faltan
                        <br></br>
                        {this.state.distance.days > 0 ? this.state.distance.days + " D " : null}{("0" + this.state.distance.hours).slice(-2) + ":" + ("0" + this.state.distance.minutes).slice(-2) + ":" + ("0" + this.state.distance.seconds).slice(-2)}
                        <br></br>
                        <span style={{ fontSize: "2.5vh", color: "#919191", fontWeight: 300 }}>para el concierto</span>
                    </div>
                    :
                    this.state.distance === "terminó" ?
                    <div style={{ padding: "0 5%", fontSize: "4vh", marginTop: "40vh" }}>
                        ¡Hoy es tu concierto!
                    </div>
                    : <CircularProgress style={{color:"#FF4644", position:"absolute", top:0, left:0, right:0, bottom:0, margin:"auto"}}/>
                }

                {/* {this.props.vatom.properties.template.indexOf("Child") === -1 ?
                    <div style={{ padding: "0 5%", marginTop: "1vh" }}>
                        <Card style={{ boxShadow: "none", width: "80%" }}>
                            <CardHeader
                                style={{ textAlign: "left" }}
                                avatar={
                                    <Avatar src={this.props.user.avatarURL} />
                                }
                                title="Tú"
                                subheader={this.props.user.displayName}
                            />
                        </Card>
                        <Card style={{ boxShadow: "none", width: "80%" }}>
                            <CardHeader
                                style={{ textAlign: "left" }}
                                avatar={
                                    <Avatar src={this.props.user.avatarURL || ""} />
                                }
                                title={this.props.vatom.properties.owner ? this.props.vatom.properties.owner.name || "Desconocido" : "Tu acompañante"}
                                subheader={this.props.vatom.properties.owner ? this.props.vatom.properties.owner.phone_number : "Envía el otro boleto"}
                            />
                        </Card>
                    </div>
                    : null} */}
                <img alt="desliza" style={{ width: "70vw", position: 'absolute', bottom: "3vh", left: 0, right: 0, margin: "0 auto" }} src={require('../../../resources/macro/desliza.png')} />
                <Dialog
                    open={this.props.vatom.properties.owner === "" && this.state.showModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogTitle id="alert-dialog-title">¡Invita a alguien!</DialogTitle>
                        <DialogContentText id="alert-dialog-description" style={{ fontWeight: 300 }}>
                            Invita a esa persona especial compartiendo uno de tus boletos.

                            Da click en el botón superior para enviarlo <SendIcon style={{ color: "#FF4644" }} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button style={{ color: "#00B3EA" }} onClick={() => { this.setState({ showModal: false }) }} autoFocus>
                            Entendido
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}