import React, { Component } from 'react';

export default class Slide2 extends Component {
    constructor() {
        super();
        this.state = {

        }
    }

    render() {
        console.log(this.props.vatom)
        return (
            <div style={{ height: "100vh", width: "100vw", margin: 0, display: "inline-block", textAlign: "center", position: "relative" }}>
                <div style={{ padding: "5%" }}>
                    <h2>Este es tu pase doble</h2>
                    <p>Presenta este código QR y una identificación en el portón Las Américas de Expo Guadalupe a las 16hrs. para acceder al evento</p>
                    <p style={{fontWeight:'lighter', color:'#919191'}}>Este código es válido para ti y tu acompañante</p>
                    <img alt="qr acceso" style={{ display: "inline-block",width: "60%"}} src={"https://api.qrserver.com/v1/create-qr-code/?size=256x256&data=redeem://" + this.props.vatom.identifier}/>
                    <p>{this.props.user.displayName}</p>
                    <img alt="desliza" style={{ width: "70vw", position: 'absolute', bottom: "3vh", left: 0, right: 0, margin: "0 auto" }} src={require('../../../resources/macro/desliza.png')} />
                </div>
            </div>
        )
    }
}