import React, { Component } from 'react'
import Slider from "react-slick";
import { CircularProgress } from '@material-ui/core'




const vatom = new window.VatomicFace.LocalVatom();


export default class MaxaPrize extends Component {
    constructor() {
        super()
        this.state = {
            vatom: {
                identifier: "Es una prueba",
                resources: {
                    CardImage: 'https://cdn.blockv.io/blockv/publisher/blockv.vticketmx/maxa_mezcal.jpg?Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYmxvY2t2LmlvLyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE1ODE4MTQyNjd9LCJEb21haW4iOiJodHRwczovL2Nkbi5ibG9ja3YuaW8ifX1dfQ__&Signature=F6Itm~QJuvKaaIo1DcaKFplVCtMFqy7ApgUSpkjOZzA7PZ5XH49OCLDNMB7rRYMQ7HR-AyncvagzouFzCUp31YeP9ufwk9KYm-temBG~OGVoTT1GtmJ4LCMLYSw69Leo~ATW61GULwZQqr88NiyZ~od5TI2PcofVFR9qL8S1y637G3xxCeN3esZ3cKM3hbruQho9V~cSCVHCZqOrUyl0AdlV2qr8rwklmSs4GrogkX9SLkbmR47og59rmfpjMRuItYb8~W2eIgTBeq3LpBpmPgY2alk~afYiHvdkn2YSa~t0xozk4b-Ac-3ALYPCCStMNMgrI3wXdl1SL~~Mn6GrhA__&Key-Pair-Id=APKAI3WYOGPWWCDCS6PA',
                },
                properties:{
                    code: 'prueba'
                }
            },
        }
    }


    componentDidMount() {
        vatom.initVatom().then((res) => {
            this.setState({ vatom: vatom, user: res.data.user })
        })
    }


    componentWillUnmount() {
        window.removeEventListener('touchstart', this.touchStart);
        window.removeEventListener('touchmove', this.preventTouch, { passive: false });
    }

    touchStart(e) {
        this.firstClientX = e.touches[0].clientX;
        this.firstClientY = e.touches[0].clientY;
    }

    preventTouch(e) {
        const minValue = 5; // threshold

        this.clientX = e.touches[0].clientX - this.firstClientX;
        this.clientY = e.touches[0].clientY - this.firstClientY;

        // Vertical scrolling does not work when you start swiping horizontally.
        if (Math.abs(this.clientX) > minValue) {
            e.preventDefault();
            e.returnValue = false;
            return false;
        }
    }

    render() {
        const settings = {
            dots: true,
            infinite: false,
            arrows: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };





        return (
            this.state.vatom ?
                <div className="maxa">
                    <div style={{ height: "100vh", width: "100vw", backgroundColor: "#f7f7f7" }}>

                        <div>
                            <div style={{
                                height: "100vh", margin: 0, display: "inline-block", textAlign: "center", position: "relative", backgroundPosition: "center", 
                                backgroundImage: `url(${this.state.vatom.resources.CardImage})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize:'cover',
                                backgroundColor: "#000", color: "#fff", 
                            }}>

                                <img alt="qr acceso" style={{
                                    display: "inline-block", width: "60%", position: 'absolute',
                                    left: 0,
                                    right: 0,
                                    bottom: '20%',
                                    margin: 'auto'
                                }} src={"https://api.qrserver.com/v1/create-qr-code/?bgcolor=a7329a&size=256x256&data=" + this.state.vatom.properties.code} />
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div style={{ position: "relative", height: "100vh", width: "100vw", textAlign: "center", fontSize: 30 }}>
                    <CircularProgress style={{ color: "#a7329a", position: "absolute", margin: "auto", top: 0, bottom: 0, left: 0, right: 0 }} />
                </div>
        )
    }
}