import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';


export default class Slide2 extends Component {
    constructor() {
        super();
        this.state = {
            showModal: false
        }
        this.handleBackClick = this.handleBackClick.bind(this)
    }

    handleBackClick(event) {
        if (event.target.id === 'back') {
            this.setState({ showModal: true })
        }
        if (event.target.id === 'cartelModal' || event.target.id === 'closeModal') {
            this.setState({ showModal: false })
        }

    }

    render() {
        return (
            <div id="back" onClick={(event) => { this.handleBackClick(event) }} style={{ height: "100vh", width: "100vw", backgroundImage: `url(${this.props.vatom.resources.CardImage2 || ""})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", margin: 0, display: "inline-block", textAlign: "center", position: "relative", backgroundPosition: "center", backgroundColor: "#000" }}>
                {this.props.vatom.properties.more_info ?
                    <a href={this.props.vatom.properties.more_info} target="_blank" style={{ fontSize: "3vh", backgroundColor: this.props.vatom.properties.btn_color, color: this.props.vatom.properties.btn_text_color, width: "90%", padding: "10px 0", position: "absolute", margin: "auto", left: 0, right: 0, bottom: "10%", borderRadius: "30px", textDecoration: "none" }}>
                        Ver sitio del evento
                </a>
                    : null}
                {this.state.showModal ?
                    <div id="cartelModal" style={{ position: "absolute", top: 0, right: 0, left: 0, bottom: 0, backgroundColor: '#000' }}>
                        <CloseIcon id='closeModal' style={{ position: "absolute", top: "5%", right: "5%", color: '#fff', zIndex: 100 }} />
                        {/* <PinchView containerRatio={100} style={{width:"100%", height:"100%"}}> */}
                        <img alt="cartel" style={{ position: "absolute", margin: "auto", top: 0, bottom: 0, width: "100%" }} src={this.props.vatom.resources.CardImage2} />
                        {/* </PinchView> */}
                    </div>
                    : null}
            </div>

        )
    }
}