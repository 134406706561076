import React, { Component } from 'react';
import Slider from "react-slick";
import BlockVFaceApi from '@blockv/faces'
import CircularProgress from '@material-ui/core/CircularProgress';
import './BusinessCards.css';
// import 'font-awesome';

const vatom = new window.VatomicFace.LocalVatom()
export default class BusinessCard extends Component {
    constructor() {
        super();
        this.state = {
            distance: 1,

            // vatom: {
            //     identifier: "Sebas es un fregón",
            //     resources: {
            //         CardImage: '',
            //         activatedImage: 'https://cdn.blockv.io/blockv/publisher/blockv.vticketmx/santi_contact.jpeg?Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYmxvY2t2LmlvLyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE1NzI2Mzk5NTl9LCJEb21haW4iOiJodHRwczovL2Nkbi5ibG9ja3YuaW8ifX1dfQ__&Signature=Xmy3PRrNiXQLOiBz8XXLTl6XKyvNfbnJKItcy-bx9K4hcIR2IYXXw-FESjMY9BZ819IyMHDO51H0R5NYvmctDR47eS8a-J~2iMkJ5sw-W2xDcp7LQsQ7-Xdgk3MbEDN~661oQXxUXwj~RAHI-IZQeI85fRnrYHhUiHXQ61JHnrOCCbx6NlxL8X-t3X~Y4JW8N6UKzTV13gB6Sq7RYCPDDtIbhGoJxPS37m60p4HAl83hFCtAT0pVMKrZjmnLZ8XV3NcKj-W0oRNDwe7IFZr12o5kZl-su1vrtP6BAAvSsESCl6jSkRdiUCZPERCLFbs3px9YFRUWfHG9NzTmJkPozA__&Key-Pair-Id=APKAI3WYOGPWWCDCS6PA'
            //     },
            //     properties: {
            //         photo: "",
            //         email: "",
            //         website: "dd",
            //         action: "",
            //         position: "CEO",
            //         company: "Bahia 360"
            //     }
            // }

        };

        vatom.initVatom().then((res) => {
            console.log("paso");
            console.log(vatom);
            this.setState({ vatom: vatom, user: res.data.user });
        });
    }

    handleActionClick(action) {
        console.log(action)
        this.setState({ action });
    }

    componentDidMount() {
        console.log("paso");
        // setInterval(() => {
        //     var today = new Date().getTime()
        //     var due_date = new Date(this.state.vatom.properties.event_date +"T23:59-06:00").getTime()
        //     var distance = due_date - today
        //     this.setState({ distance:distance })
        // },1000)
        window.addEventListener('touchstart', this.touchStart);
        window.addEventListener('touchmove', this.preventTouch, { passive: false });
    }

    handleRedemption() {
        var payload = {
            "actionName": "Redeem",
            "actionData": {
                "this.id": this.state.vatom.identifier,
                "new.owner.id": "ae9f9977-4723-41a2-b852-6b266e41543a"
            }
        }
        window.VatomicFace.VatomicBridge.sendRequest("vatom.performAction", payload).then(res => {
            console.log(res)
            if (typeof (res['errorCode']) != 'undefined') {
                alert("Ocurrió un problema, por favor inténtalo más tarde")
                window.VatomicFace.VatomicBridge.sendRequest("vatom.view.close")
            }
            else {
                window.VatomicFace.VatomicBridge.sendRequest("vatom.view.close")
            }
        })
    }


    componentWillUnmount() {
        window.removeEventListener('touchstart', this.touchStart);
        window.removeEventListener('touchmove', this.preventTouch, { passive: false });
    }

    touchStart(e) {
        this.firstClientX = e.touches[0].clientX;
        this.firstClientY = e.touches[0].clientY;
    }

    preventTouch(e) {
        const minValue = 5; // threshold

        this.clientX = e.touches[0].clientX - this.firstClientX;
        this.clientY = e.touches[0].clientY - this.firstClientY;

        // Vertical scrolling does not work when you start swiping horizontally.
        if (Math.abs(this.clientX) > minValue) {
            e.preventDefault();
            e.returnValue = false;
            return false;
        }
    }

    async goBackHere(image, action) {
        console.log(image + ' ' + action);
        const url = "https://api.jalo.app/upload_image";
        let data = {
            "foto": image,
            "vatom_id": this.state.vatom.identifier
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        }).then(response => {
            return response.json();
        }).then(async res => {
            console.log(res);
            var vatom = this.state.vatom
            var payload = {
                "actionName": "Update",
                "actionData": {
                    "this.id": vatom.identifier,
                    "properties": {
                        "photo": image,
                        "action": action
                    }
                }
            }

            vatom.properties.photo = image;
            vatom.properties.action = action;

            await window.VatomicFace.VatomicBridge.sendRequest("vatom.performAction", payload)
            this.setState({
                distance: 1,
                vatom
            });
            // resolve();
        });
    }

    handleAddContact() {
        const contact = {
            "displayName": this.state.vatom.properties.name,
            "jobTitle": this.state.vatom.properties.job_title,
            "company": this.state.vatom.properties.company,
            "phoneNumbers": [{
                label: "work",
                number: this.state.vatom.properties.mobile_phone,
            }],
            "emailAddresses": [{
                label: "work",
                email: this.state.vatom.properties.email,
            }],
            "urlAddresses": [{
                label: "work",
                url: this.state.vatom.properties.website,
            }],
        }
        window.ReactNativeWebView.postMessage(`{ "name": "addContact", "contact": ${JSON.stringify(contact)}}`)
    }
    render() {
        const dimensions = 40;
        const styles = {
            container: {
                fontFamily: 'Roboto',
                height: "100vh",
                width: "100vw",
                // backgroundColor: '#FFF',
                margin: 0,
                display: "inline-block",
                textAlign: "center",
                position: "relative",
                color: '#000',
            },
            img: {
                marginTop: '10vw',
                borderRadius: '50%',
                width: dimensions + 'vw',
                height: dimensions + 'vw',
                objectFit: 'cover',
            },
            nombre: {
                fontSize: '3vh',
                fontWeight: '700'
            },
            workStuff: {
                fontSize: '2.5vh',
                fontWeight: '700'
            },
            ciudad: {
                fontSize: '1.5vh',
                fontWeight: '300'
            },
            datosContacto: {
                paddingTop: '10vh'
            },
            botonGuardar: {
                marginTop: '15vh',
                width: '55vw',
                padding: '1.5vh',
                borderRadius: '50px',
                position: 'absolute',
                bottom: '10vh',
                left: 0,
                right: 0,
                margin: '0 auto'
            }
        }
        return (
            this.state.vatom ?
                <div style={styles.container} class="container" >
                    <img style={styles.img} src={this.state.vatom.resources.ActivatedImage} alt={this.state.vatom.properties.name} />
                    <div>
                        <p style={styles.nombre}>{this.state.vatom.properties.name}</p>
                        <p style={styles.workStuff}>
                            <span>{this.state.vatom.properties.job_tiitle}</span> - <span>{this.state.vatom.properties.company}</span>
                        </p>
                        <p style={styles.ciudad}>México, Ciudad De México.</p>
                    </div>
                    <div style={styles.datosContacto}>
                        <p>
                            <i class="fas fa-phone"></i> <a href={"tel:" + this.state.vatom.properties.mobile_phone}>{this.state.vatom.properties.mobile_phone}</a>
                        </p>
                        <p>
                            <i class="fas fa-at"></i>
                            <a href={"mailto:" + this.state.vatom.properties.email}>{this.state.vatom.properties.email}</a>
                        </p>
                        <p>
                            <a href={this.state.vatom.properties.website} target="_blank">{this.state.vatom.properties.website}</a>
                        </p>
                    </div>
                    {this.state.vatom.properties.template.indexOf("Child") != -1 ?
                        <a onClick={this.handleAddContact.bind(this)}>
                            <div style={styles.botonGuardar} class="botonGuardar">
                                Agregar a contactos
                            </div>
                        </a>
                        : null
                    }
                </div>
                : <CircularProgress style={{ color: "#00abe1", position: "absolute", top: 0, left: 0, right: 0, bottom: 0, margin: "auto" }} />
        )
    }
}