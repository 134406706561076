import React, { Component } from 'react';
import ReactPlayer from 'react-player'

export default class Slide1 extends Component {
    constructor() {
        super();
        this.state = {

        }
    }
    componentWillMount() {
    }

    countdown() {

    }

    render() {
        return (
            <div style={{ height: "100vh", width: "100vw", backgroundImage: `url(${this.props.vatom.resources.CardImage || ""})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", margin: 0, display: "inline-block", textAlign: "center", position: "relative", backgroundSize: 'cover', backgroundPosition: 'bottom center', color: '#fff' }}>
                <ReactPlayer
                    width='100%'
                    height='100%'
                    url='https://cdn.blockv.io/blockv/publisher/blockv.vticketmx/MesMoto.mp4?Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYmxvY2t2LmlvLyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE1NzAxMjQzMzh9LCJEb21haW4iOiJodHRwczovL2Nkbi5ibG9ja3YuaW8ifX1dfQ__&Signature=TZ9xaBMdLTgCoqx~qlMd20A6TGvHZ64xkVL~7igzrD90R0RP9E5iRr~vhSVxDy8ZMSWM0tUN5rMrlub06zGMoE9HIPdeCjMeUPJBk5-FAzuaRHCczXCdcs01LQ9hmIF5ke3DpYl-aZX0QcUUDPbuha-s7P~WvP8md9USQxkdymJWIYsseBuuX5JfddSCax7QhHX1k3rGEYwmcPvjgPUHxjGQKWc9WRfe-ExOzNc-QAdpzrJ4631UjkBCwOQzeV9LKJQ1UmGb1eW1KzdIdlL-jDkwshgROWwgFgxrmpIr9wc~Aer53FRhvqKxrIH13X~pWVwDdF41V1sVC7ZLoXV9zA__&Key-Pair-Id=APKAI3WYOGPWWCDCS6PA'
                    playing
                    onEnded={() => { console.log('termino') }}
                />
                {/* <img alt="desliza" style={{ width: "70vw", position: 'absolute', bottom: "3vh", left: 0, right: 0, margin: "0 auto" }} src={require('../../../resources/macro/desliza_motorola.png')} /> */}
            </div>
        )
    }
}