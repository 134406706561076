import React, { Component } from 'react'
import { CircularProgress } from '@material-ui/core'
import * as typeformEmbed from '@typeform/embed'

const vatom = new window.VatomicFace.LocalVatom();


export default class Survey extends Component {
    constructor() {
        super()
        this.state = {
            // vatom: {
            //     identifier: "Es una prueba",
            //     properties: {
            //         survey_id: "n6FW7N",
            //         redeem_button_text_color: '#fafafa',
            //         redeem_text: "Redimir",
            //         redeem_button_background_color: "green"
            //     },
            //     resources: {
            //         CardImage: 'https://cdn.blockv.io/blockv/publisher/blockv.vticketmx/att_rally_back.jpg?Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYmxvY2t2LmlvLyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE1ODE4MTQyNjd9LCJEb21haW4iOiJodHRwczovL2Nkbi5ibG9ja3YuaW8ifX1dfQ__&Signature=F6Itm~QJuvKaaIo1DcaKFplVCtMFqy7ApgUSpkjOZzA7PZ5XH49OCLDNMB7rRYMQ7HR-AyncvagzouFzCUp31YeP9ufwk9KYm-temBG~OGVoTT1GtmJ4LCMLYSw69Leo~ATW61GULwZQqr88NiyZ~od5TI2PcofVFR9qL8S1y637G3xxCeN3esZ3cKM3hbruQho9V~cSCVHCZqOrUyl0AdlV2qr8rwklmSs4GrogkX9SLkbmR47og59rmfpjMRuItYb8~W2eIgTBeq3LpBpmPgY2alk~afYiHvdkn2YSa~t0xozk4b-Ac-3ALYPCCStMNMgrI3wXdl1SL~~Mn6GrhA__&Key-Pair-Id=APKAI3WYOGPWWCDCS6PA',
            //     }
            // },
            // user: {
            //     displayName: "Sebastian Sanchez Alcala"
            // },
            imageLoaded: false,
            survey_done: false,
            isStarted: false,
            stepNumber: 1,
            number: ""
        }
    }

    async componentDidMount() {
        vatom.initVatom().then(async () => {
            await this.setState({ vatom: vatom })
            const url = 'https://vvb.typeform.com/to/' + this.state.vatom.properties.survey_id + '?vatom_id=' + this.state.vatom.identifier
            const popup = typeformEmbed.makePopup(url, { mode: 'popup', onSubmit: this.handleSurveyDone.bind(this), autoStart: true })
            this.popup = popup
        })
        const url = 'https://vvb.typeform.com/to/' + this.state.vatom.properties.survey_id + '?vatom_id=' + this.state.vatom.identifier
        const popup = typeformEmbed.makePopup(url, { mode: 'popup', onSubmit: this.handleSurveyDone.bind(this), autoStart: true })
        this.popup = popup
    }



    setStart = () => {
        this.popup.open()
        this.setState({
            stepNumber: 2
        })
    }

    handleSurveyDone() {
        this.popup.close()
        // this.survey.style.display="none";
        // console.log("paso");
        this.setState({ survey_done: true })
        // console.log(this.state.survey_done);
        this.setState({
            stepNumber: 3
        })
    }
    

    handleRedeem() {
        var payload = {
            "actionName": "Redeem",
            "actionData": {
                "this.id": this.state.vatom.identifier,
                "new.owner.id": "ae9f9977-4723-41a2-b852-6b266e41543a"
            }
        }
        window.VatomicFace.VatomicBridge.sendRequest("vatom.performAction", payload).then(response => {
            if (typeof (response['errorCode']) != 'undefined') {
                console.log(response);
            }
            else {
                window.VatomicFace.VatomicBridge.sendRequest("vatom.view.close")
            }
        });
    }

    getMedal = () => {
        var payload = {
            "actionName": "Redeem",
            "actionData": {
                "this.id": this.state.vatom.identifier,
                "new.owner.id": "ae9f9977-4723-41a2-b852-6b266e41543a"
            }
        }
        window.VatomicFace.VatomicBridge.sendRequest("vatom.performAction", payload).then(response => {
            if (typeof (response['errorCode']) != 'undefined') {
                console.log(response);
            }
            else {
                window.VatomicFace.VatomicBridge.sendRequest("vatom.view.close")
            }
        });
    }

    render() {
        
        return (
            this.state.vatom ?
                <div className="ATTSamsung" style={{ backgroundImage: `url(${this.state.vatom.resources.CardImage})` }}>
                    <div style={{ height: "100vh", width: "100vw" }}>

                        {this.state.stepNumber == 1 ?


                            <div>
                                <div className="ATT-Section">
                                    <div>
                                        <p className="Title-ATT">
                                            Regístrate en el Rally AT&T para ganar un Samsung
                                        </p>
                                        <p>
                                            Para comenzar, necesitas contestar la siguiente encuesta
                                        </p>

                                        <button className="ATTSamsung-button" onClick={this.setStart} >Comenzar </button>

                                    </div>
                                </div>
                            </div>
                            : (this.state.stepNumber == 2 ?

                                <div style={{ width: '100vw', height: '100vh' }}>
                                    {!this.state.survey_done ?
                                        null
                                        :
                                        <div style={{ width: "100%", height: "100%" }}>
                                            {!this.state.imageLoaded ?
                                                <CircularProgress style={{ color: "#999999", position: "absolute", margin: "auto", top: 0, bottom: 0, left: 0, right: 0 }} />
                                                : null}
                                            <img onLoad={() => this.setState({ imageLoaded: true })} src={this.state.vatom.resources.CardImage} style={{ width: "100%", height: "100%", overflowY: "scroll" }} />
                                            <div style={{ height: "90px", position: "fixed", bottom: "0", width: "100%" }}>
                                                <a onClick={this.handleRedeem.bind(this)} style={{
                                                    cursor: "pointer",
                                                    width: "80%", height: "55px", display: "block", margin: "15px auto", color: this.state.vatom.properties.redeem_button_text_color, backgroundColor: this.state.vatom.properties.redeem_button_background_color, borderRadius: "50px",
                                                    textAlign: "center", lineHeight: "55px", fontWeight: 700
                                                }}>{this.state.vatom.properties.redeem_text}</a>
                                            </div>
                                        </div>
                                    }

                                </div>



                                :
                                <div>
                                    <div className="ATT-Section">
                                        <div>
                                            <p className="Title-ATT">
                                                ¡Excelente!                                            </p>
                                            <p>
                                                Estás listo para comenzar el rally, recuerda que solamente la persona que lo termine en el menor tiempo será la ganadora                                             </p>

                                            <button className="ATTSamsung-button" onClick={this.getMedal} >Comenzar Rally</button>

                                        </div>
                                    </div>
                                </div>
                            )

                        }
                    </div>
                </div>
                :
                <div style={{ position: "relative", height: "100vh", width: "100vw", textAlign: "center", fontSize: 30 }}>
                    <CircularProgress style={{ color: "#a7329a", position: "absolute", margin: "auto", top: 0, bottom: 0, left: 0, right: 0 }} />
                </div>
        )
    }
}