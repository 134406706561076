import React, { Component } from 'react';
import 'conekta-card-tokenizer'
import JaloAPI from '../../SDK/JaloAPI'

export default class Test extends Component {
    constructor() {
        super();
        this.state = {
            user_id: "77ca91ef-8c3a-4daf-b0ce-7881a1e52117"
        }
    }

    componentDidMount() {
        window.Conekta.setPublicKey("key_Af7BrovbKv9P5zD9yQGWGXw")
        window.Conekta.setLanguage("es")

        this.getCards()
    }

    async getCards(){
        const cards = await JaloAPI.getCards(this.state.user_id)
        console.log(cards)
        this.setState({cards:cards.data.cards})
    }

    async handleDelete(idx){
        const deleted = await JaloAPI.deleteCardByIdx(this.state.user_id,idx)
        console.log(deleted)
        this.getCards()
    }

    render() {
        return (
            <div>
                {this.state.cards ? this.state.cards.map((card, idx)=>{
                    console.log(card);
                    return(
                        <div key={card.last_4} style={{display:"inline-block"}}>
                            <p>{card.brand}</p>
                            <p>{card.last_4}</p>
                            <button onClick={this.handleDelete.bind(this,idx)}>Delete</button>
                        </div>
                        )
                }):
                "cargando..."}
                <br></br>
                <button>Agregar nueva tarjeta</button>
            </div>
        )
    }
}