import React, { Component } from 'react';
import './style/Exa.css'
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class ExaTelcelVIP extends Component {
    constructor() {
        super()
        this.state = {
            camera: false,
            photo: null,
            photoApproved: false
        }
    }

    componentDidMount() {
        window.addEventListener("getPhoto", this.subirFoto.bind(this))
    }

    renderCositas() {
        if (!this.state.photoApproved && this.state.photo) {
            return (
                <div style={{ width: "100vw", height: "100vh" }}>
                    <img src={this.state.photo} style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                    <button style={{ position: "absolute", bottom: "10vh", left: "2vw", color: "#fff", backgroundColor: "#28a745", borderColor: "#28a745" }} onClick={() => this.setState({ photoApproved: true })}>
                        Aceptar
                    </button>
                    <button style={{ position: "absolute", bottom: "10vh", right: "2vw", color: "#fff", backgroundColor: "#dc3545", borderColor: "#dc3545" }} onClick={() => this.setState({ photoApproved: false, photo: null, camera: true })}>
                        Cancelar
                    </button>
                </div>
            )
        } else if (!this.state.photoApproved && !this.state.photo) {
            return (
                <div>
                    <span style={{ position: "absolute", top: "5%", right: "5%", zIndex: 100 }} onClick={() => { this.setState({ camera: false }) }}>Cerrar</span>
                    <Camera
                        onTakePhoto={(dataUri) => { console.log(dataUri); this.setState({ photo: dataUri }) }}
                        idealFacingMode={FACING_MODES.ENVIRONMENT}
                        isImageMirror={false}
                    />
                </div>
            )
        } else if (this.state.photoApproved && this.state.photo) {
            // TODO: subir la foto al server
            return <CircularProgress style={{ color: "#00E4A0", position: "absolute", top: 0, left: 0, right: 0, bottom: 0, margin: "auto" }} />;
        }
    }

    async subirFoto(e) {
        this.setState({ photoApproved: true, photo: "true", camera: true })
        console.log(e.detail)
        await this.props.goBackHere(e.detail, "vip");
    }

    render() {
        return (
            !this.state.camera ?
                <div style={{ padding: "5%" }}>
                    <h2>Dinámica para 6 boletos generales</h2>
                    <p style={{ fontSize: "2.7vh" }}>
                        <span>
                            1. realiza alguna de las siguientes acciones.
                        </span>
                        <br />
                        <span>
                            a. Compra un equipo Motorola.
                        </span>
                        <br />
                        <span>
                            b. Renueva o contrata un plan
                        </span>
                        <br />
                        <span>
                            2. Sube la foto del comprobante.
                        </span>
                        <br />
                        <span>
                            3. Regresa con el representante de Motorola.
                        </span>
                    </p>
                    <a className="motorola-btn" onClick={() => window.ReactNativeWebView.postMessage('{ "name": "openCamera" }')}>Tomar Foto</a>
                    <a onClick={() => { this.props.handleBack() }} style={{ width: "70vw", position: 'absolute', bottom: "3vh", left: 0, right: 0, margin: "0 auto" }}>Regresar</a>
                </div>
                :
                <div>
                    {this.renderCositas()}
                </div>
        )
    }
}