import React, { Component } from 'react';
import Slider from "react-slick";
import './style/Macro.css'
import BlockVFaceApi from '@blockv/faces'


import Slide1 from './Slide1'
import Slide2 from './Slide2'
import Slide3 from './Slide3'

const vatom = new window.VatomicFace.LocalVatom()
export default class Macro extends Component {
    constructor() {
        super()
        this.state = {
            vatom: {
                identifier: "Sebas es un fregón",
                properties: {
                    event_date: 1871263200000,
                    more_info: 'http://mvsradio.com/',
                    cloned_from: "",
                    num_direct_clones: 0,
                    owner: ""
                },
                resources: {
                    CardImage: 'http://armadadigital.mx/TicketMacro_card.jpg',
                    CardImage2: 'http://armadadigital.mx/TicketMacro_card2.jpg'
                }
            },
            user: {
                avatarURL: "https://cdn.blockv.io/blockv/avatars/06149a38-ced6-44e2-9ebf-4921fb5cbcee.1536703749782542737?Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYmxvY2t2LmlvLyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE1NDA0ODUzOTV9LCJEb21haW4iOiJodHRwczovL2Nkbi5ibG9ja3YuaW8ifX1dfQ__&Signature=IdYl8uLODWGkiTc2qJp8Yi5W3fNFE1xaXfz9uhfZnehfVtFCTZ8rCb1Q6wecBcLyedXl0~gyLY9NV8kQaFXb6NBkaYaCxvLVfY47wSELvOOx9fYbRnxr7XYJxVFLPy-S06GA8GHen5Sg8PeD5gLSznyR531pct1SqZuIh02hYbnMgIriq0CVaDb4yYMOeCFf8msm2LmYVc-tq8~fTGd4oNUPuxyCgXgVnSYv0DgLgeAaJOK5KJplL9V~YxPIWZCc7E1W3Q1DpvTbcc8edhXVwpY~Ko2BsyK1NFCa8LUO6Nw8g1JWVp9OKYIQ3UCgHyHMN8ErhRw-MV5Q7erCzgCJZg__&Key-Pair-Id=APKAI3WYOGPWWCDCS6PA",
                displayName: "Pruebas Variv",
                firstName: "Pruebas",
                id: "06149a38-ced6-44e2-9ebf-4921fb5cbcee",
                lastName: "Variv"
            }
        }
        vatom.initVatom().then((res) => {
            this.setState({ vatom: vatom, user: res.data.user })
        })
        // alert("estoy cargando...")
        // const x = setInterval( () => {
        //     vatom.initVatom().then(res => {
        //         console.log(res)
        //         alert("pasaré")
        //         // clearInterval(x)
        //         this.setState({ vatom: res })
        //     })},500)
    }

    componentDidMount() {
        var today = new Date().getTime()
        var due_date = new Date(157532429200000).getTime()
        var distance = due_date - today
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        console.log(days)
        this.setState({ days })
        window.addEventListener('touchstart', this.touchStart);
        window.addEventListener('touchmove', this.preventTouch, {passive: false});
    }

    handleRedemption(){
        var payload={
            "actionName" : "Redeem",
                "actionData" : {
                    "this.id" : this.state.vatom.identifier,
                    "new.owner.id": "ae9f9977-4723-41a2-b852-6b266e41543a"
                }
        }
        window.VatomicFace.VatomicBridge.sendRequest("vatom.performAction",payload).then(res=>{
            console.log(res)
            if(typeof (res['errorCode']) != 'undefined'){
                alert("Ocurrió un problema, por favor inténtalo más tarde")
                window.VatomicFace.VatomicBridge.sendRequest("vatom.view.close")
            }
            else{
                window.VatomicFace.VatomicBridge.sendRequest("vatom.view.close")
            }
        })
    }

    
    componentWillUnmount(){
        window.removeEventListener('touchstart', this.touchStart);
        window.removeEventListener('touchmove', this.preventTouch, {passive: false});
    }
    
    touchStart(e){
        this.firstClientX = e.touches[0].clientX;
        this.firstClientY = e.touches[0].clientY;
    }
    
    preventTouch(e){
        const minValue = 5; // threshold
    
        this.clientX = e.touches[0].clientX - this.firstClientX;
        this.clientY = e.touches[0].clientY - this.firstClientY;
    
        // Vertical scrolling does not work when you start swiping horizontally.
        if(Math.abs(this.clientX) > minValue){ 
            e.preventDefault();
            e.returnValue = false;
            return false;
        }
    }

    render() {
        const settings = {
            dots: true,
            infinite: false,
            arrows: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            this.state.vatom && this.state.days > -1 ?
                <Slider {...settings} adaptiveHeight={true}>
                    {this.state.vatom.properties.owner === "" ?
                        <div>
                            <Slide3 vatom={this.state.vatom} user={this.state.user}/>
                        </div>
                        : null}
                    <div>
                        <Slide1 vatom={this.state.vatom} user={this.state.user} />
                    </div>
                    <div>
                        <Slide2 vatom={this.state.vatom} />
                    </div>
                </Slider>
                :
                this.state.vatom ?
                    <div style={{ height: "100vh", width: "100vw", backgroundImage: `url(${this.state.vatom.resources.CardImage || ""})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", margin: 0, display: "inline-block", textAlign: "center", position: "relative" }}>
                        <div style={{ padding: "0 5%", marginTop: "40vh" }}>
                            <h1>¡Gracias por asistir!</h1>
                            <p>Espera pronto más boletos</p>
                            <p>Marca este concierto como completado y junta experiencias</p>    
                            <a style={{fontSize:"3vh", backgroundColor:'#FFEE3D', color: '#275DAF', width:"90%", padding:"10px 0", position:"absolute", margin:"auto",left:0, right:0, bottom: "10%", borderRadius: "30px", textDecoration:"none"}} onClick={this.handleRedemption.bind(this)}>¡Completar!</a>
                        </div>
                    </div>
                    : null

        )
    }
}