import React, { Component } from 'react';

export default class Slide2 extends Component {
    constructor() {
        super();
        this.state = {

        }
    }

    render() {
        return (
            <div style={{ height: "100vh", width: "100vw", margin: 0, display: "inline-block", textAlign: "center", position: "relative" }}>
                <div style={{ padding: "5%" }}>
                    <h2>Este es tu pase doble</h2>
                    <p style={{fontSize:"3vh"}}>
                        {this.props.vatom.properties.entrance_instructions}
                        {/* Presenta este código Q.R, tu aplicación y una identificación oficial en <a href={"https://goo.gl/maps/DMfhroQgKptv2Hki7"} target="_blank">calle Copérnico esquina Mariano Escobedo</a> hasta un día antes del evento en un horario de 8 a 18 hrs. */}
                    </p>
                    <p style={{fontWeight:'lighter', color:'#919191',fontSize:"2.5vh"}}>Este código es válido para ti y tu acompañante</p>
                    <img alt="qr acceso" style={{ display: "inline-block",width: "60%"}} src={"https://api.qrserver.com/v1/create-qr-code/?size=256x256&data=redeem://" + this.props.vatom.identifier}/>
                    <p>{this.props.user.displayName}</p>
                    <img alt="desliza" style={{ width: "70vw", position: 'absolute', bottom: "3vh", left: 0, right: 0, margin: "0 auto" }} src={require('../../../resources/macro/desliza.png')} />
                </div>
            </div>
        )
    }
}