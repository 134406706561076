import React, { Component } from 'react';
import Slider from "react-slick";
import './style/Exa.css'
import BlockVFaceApi from '@blockv/faces'
import CircularProgress from '@material-ui/core/CircularProgress';
import ExaTelcelTickets from './ExaTelcelTickets'
import ExaTelcelVIP from './ExaTelcelVIP'

const vatom = new window.VatomicFace.LocalVatom()
export default class ExaTelcelContest extends Component {
    constructor() {
        super()
        this.state = {
            distance: 1,
            // vatom: {
            //     identifier: "Sebas es un fregón",
            //     resources: {
            //         CardImage: 'https://cdn.blockv.io/blockv/publisher/blockv.vticketmx/exa_motorola_back.png?Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYmxvY2t2LmlvLyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE1NzAxMjQzMzh9LCJEb21haW4iOiJodHRwczovL2Nkbi5ibG9ja3YuaW8ifX1dfQ__&Signature=TZ9xaBMdLTgCoqx~qlMd20A6TGvHZ64xkVL~7igzrD90R0RP9E5iRr~vhSVxDy8ZMSWM0tUN5rMrlub06zGMoE9HIPdeCjMeUPJBk5-FAzuaRHCczXCdcs01LQ9hmIF5ke3DpYl-aZX0QcUUDPbuha-s7P~WvP8md9USQxkdymJWIYsseBuuX5JfddSCax7QhHX1k3rGEYwmcPvjgPUHxjGQKWc9WRfe-ExOzNc-QAdpzrJ4631UjkBCwOQzeV9LKJQ1UmGb1eW1KzdIdlL-jDkwshgROWwgFgxrmpIr9wc~Aer53FRhvqKxrIH13X~pWVwDdF41V1sVC7ZLoXV9zA__&Key-Pair-Id=APKAI3WYOGPWWCDCS6PA',
            //     },
            //     properties: {
            //         action1_text: "Quiero 2 Boletos generales",
            //         action2_text: "Quiero 6 Boletos generales",
            //         action1: "ticket",
            //         action2: "vip",
            //         photo: "",
            //         action: "tickets"
            //     }
            // }
        }

        vatom.initVatom().then((res) => {
            console.log("paso")
            console.log(vatom)
            this.setState({ vatom: vatom, user: res.data.user })
        })
    }

    handleActionClick(action) {
        this.setState({ action });
    }

    componentDidMount() {
        console.log("paso")
        // setInterval(() => {
        //     var today = new Date().getTime()
        //     var due_date = new Date(this.state.vatom.properties.event_date +"T23:59-06:00").getTime()
        //     var distance = due_date - today
        //     this.setState({ distance:distance })
        // },1000)
        window.addEventListener('touchstart', this.touchStart);
        window.addEventListener('touchmove', this.preventTouch, { passive: false });
    }

    handleRedemption() {
        var payload = {
            "actionName": "Redeem",
            "actionData": {
                "this.id": this.state.vatom.identifier,
                "new.owner.id": "ae9f9977-4723-41a2-b852-6b266e41543a"
            }
        }
        window.VatomicFace.VatomicBridge.sendRequest("vatom.performAction", payload).then(res => {
            console.log(res)
            if (typeof (res['errorCode']) != 'undefined') {
                alert("Ocurrió un problema, por favor inténtalo más tarde")
                window.VatomicFace.VatomicBridge.sendRequest("vatom.view.close")
            }
            else {
                window.VatomicFace.VatomicBridge.sendRequest("vatom.view.close")
            }
        })
    }


    componentWillUnmount() {
        window.removeEventListener('touchstart', this.touchStart);
        window.removeEventListener('touchmove', this.preventTouch, { passive: false });
    }

    touchStart(e) {
        this.firstClientX = e.touches[0].clientX;
        this.firstClientY = e.touches[0].clientY;
    }

    preventTouch(e) {
        const minValue = 5; // threshold

        this.clientX = e.touches[0].clientX - this.firstClientX;
        this.clientY = e.touches[0].clientY - this.firstClientY;

        // Vertical scrolling does not work when you start swiping horizontally.
        if (Math.abs(this.clientX) > minValue) {
            e.preventDefault();
            e.returnValue = false;
            return false;
        }
    }

    async goBackHere(image, action) {
        console.log(image + ' ' + action);
        const url = "https://api.jalo.app/upload_image";
        let data = {
            "foto": image,
            "vatom_id": this.state.vatom.identifier
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        }).then(response => {
            return response.json();
        }).then(async res => {
            console.log(res);
            var vatom = this.state.vatom
            var payload = {
                "actionName": "Update",
                "actionData": {
                    "this.id": vatom.identifier,
                    "properties": {
                        "photo": image,
                        "action": action
                    }
                }
            }

            vatom.properties.photo = image;
            vatom.properties.action = action;

            await window.VatomicFace.VatomicBridge.sendRequest("vatom.performAction", payload)
            this.setState({
                distance: 1,
                vatom
            });
            // resolve();
        });
    }

    render() {
        if (this.state.vatom && this.state.distance > 0 && this.state.vatom.properties.photo == "") {
            return (
                <div style={{ height: "100vh", width: "100vw", backgroundImage: `url(${this.state.vatom.resources.CardImage || ""})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", margin: 0, display: "inline-block", textAlign: "center", position: "relative", backgroundSize: 'cover', backgroundPosition: 'bottom center', color: '#fff' }}>
                    {!this.state.action ?
                        <div style={{ padding: "5%" }}>
                            <h2>¡Felicidades!</h2>
                            <p style={{ fontSize: "2.7vh" }}>
                                Para recibir tus boletos indica una de las siguientes opciones:
                        </p>
                            <div style={{ textAlign: "center" }}>
                                {/* {this.state.vatom.properties.action1 ?
                                    <a className="motorola-btn" onClick={this.handleActionClick.bind(this, this.state.vatom.properties.action1)}>{this.state.vatom.properties.action1_text}</a>
                                    : null} */}
                                {this.state.vatom.properties.action2 ?
                                    <a className="motorola-btn" onClick={this.handleActionClick.bind(this, this.state.vatom.properties.action2)}>{this.state.vatom.properties.action2_text}</a>
                                    : null}
                            </div>
                        </div>
                        : this.state.action == 'vip' ?
                            <ExaTelcelVIP vatom={this.state.vatom} handleBack={this.handleActionClick.bind(this, "")} goBackHere={this.goBackHere.bind(this)} />
                            : <ExaTelcelTickets vatom={this.state.vatom} handleBack={this.handleActionClick.bind(this, "")} goBackHere={this.goBackHere.bind(this)} />}
                </div>
            )
        } else if (this.state.vatom && this.state.distance > 0 && this.state.vatom.properties.photo != "") {
            const settings = {
                dots: true,
                infinite: false,
                arrows: false,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1
            };
            return (
                <Slider {...settings} adaptiveHeight={true}>
                    <div>
                        <div style={{ height: "100vh", width: "100vw", backgroundImage: `url(${this.state.vatom.resources.CardImage || ""})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", margin: 0, display: "inline-block", textAlign: "center", position: "relative", backgroundSize: 'cover', backgroundPosition: 'bottom center', color: '#fff' }}>
                            <img src={this.state.vatom.properties.photo} style={{ width: "100vw", height: "90vh", objectFit: "contain" }} />
                            <img alt="desliza" style={{ width: "70vw", position: 'absolute', bottom: "3vh", left: 0, right: 0, margin: "0 auto" }} src={require('../../../resources/macro/desliza_motorola.png')} />
                        </div>
                    </div>
                    <div>
                        <div style={{ height: "100vh", width: "100vw", backgroundImage: `url(${this.state.vatom.resources.CardImage || ""})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", margin: 0, display: "inline-block", textAlign: "center", position: "relative", backgroundSize: 'cover', backgroundPosition: 'bottom center', color: '#fff' }}>
                            <div style={{ padding: "5%" }}>
                                <h2>¡Has ganado tus boletos!</h2>
                                <p>Acude con el representante Motorola y pide que escaneé el código QR para que te entregue tus boletos</p>
                                <img alt="qr acceso" style={{ display: "inline-block", width: "60%" }} src={"https://api.qrserver.com/v1/create-qr-code/?size=256x256&color=00E4A0&data=redeem://" + this.state.vatom.identifier + "://" + this.state.vatom.properties.action} />
                                <img alt="desliza" style={{ width: "70vw", position: 'absolute', bottom: "3vh", left: 0, right: 0, margin: "0 auto" }} src={require('../../../resources/macro/desliza_motorola.png')} />
                            </div>
                        </div>
                    </div>
                </Slider>
            )
        } else {
            return (<CircularProgress style={{ color: "#00E4A0", position: "absolute", top: 0, left: 0, right: 0, bottom: 0, margin: "auto" }} />)
        }
    }
}