import React, { Component } from 'react'

export default class PolygonButton extends Component {
    render() {
        return (
            <div style={{
                ...this.props.style, position: "relative",
                cursor: "pointer", width: "100%", height: "100%", color: "#000"
            }}>
                <div className="polygon" style={{ lineHeight: this.props.height || "5vh", fontSize: this.props.fontSize || "1.8vh" }}>
                    {this.props.text}
                </div>
                <div className="polygon_shadow" style={{ backgroundColor: this.props.shadowColor || "#00abe1" }}>
                </div>
            </div>
        )
    }
}
