import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

//Routes
import Macro from './Components/Tickets/Macro/Macro'
import Exa from './Components/Tickets/Exa/Exa'
import ExaTelcel from './Components/Tickets/Exa19-Telcel/ExaTelcel'
import ExaTelcelContest from './Components/Tickets/Exa19-Telcel/ExaTelcelContest'
import ExaTips from './Components/Tips/Exa/ExaTips';
import Conekta_test from './Components/Conketa/Test';
import Conekta_full_test from './Components/Conketa/TestFull';
import OxxoTest from './Components/Oxxo/OxxoTest';
import ATTClasicoContest from './Components/Tickets/ATT/Clasico/ATTClasicoContest';
import ATTClasico from './Components/Tickets/ATT/Clasico/ATTClasico';
import BusinessCard from './Components/BusinessCards/BusinessCard';
import StarbucksCoupon from './Components/Starbucks/StarbucksCoupon';
import PolygonChristmasContest from './Components/Tickets/Polygon/Christmas/PolygonChristmasContest'
import PolygonChristmasDone from './Components/Tickets/Polygon/Christmas/PolygonChristmasDone'
import Maxa from './Components/Tickets/Maxa/Maxa'
import MaxaPrize from './Components/Tickets/Maxa/MaxaPrize'
import Redeem from './Components/ATTRally/Redeem';
import Share from './Components/ATTRally/Share';
import Camera from "./Components/ATTRally/Camera"
import Survey from './Components/ATTRally/Survey'
import SabritasSurvey from './Components/Pepsi/Survey'
import PepsiShare from './Components/Pepsi/Share';
import PepsiCombineable from './Components/Pepsi/Combineable';


export default class App extends Component {
  render() {
    return (
      <Router >
        <Switch>
          <Route path="/macro" component={Macro} />
          <Route path="/exa_tips" component={ExaTips} />
          <Route path="/exa" component={Exa} />
          <Route path="/exa_motorola" component={ExaTelcel} />
          <Route path="/exa_motorola_contest" component={ExaTelcelContest} />
          <Route path="/conekta_test" component={Conekta_test} />
          <Route path="/conekta_full_test" component={Conekta_full_test} />
          <Route path="/oxxo" component={OxxoTest} />
          <Route path="/att/clasico" component={ATTClasico} />
          <Route path="/att/clasico_contest" component={ATTClasicoContest} />
          <Route path="/BusinessCard" component={BusinessCard} />
          <Route path="/StarbucksCoupon" component={StarbucksCoupon} />
          <Route path="/polygon/christmas/contest" component={PolygonChristmasContest} />
          <Route path="/polygon/christmas/done" component={PolygonChristmasDone} />
          <Route path="/maxa/prize" component={MaxaPrize} />
          <Route path="/maxa" component={Maxa} />
          {/* <Route path="/survey" component={Survey2} /> */}
          <Route path="/attrally/redeem" component={Redeem} />
          <Route path="/attrally/share" component={Share} />
          <Route path="/attrally/camera" component={Camera} />
          <Route path="/attrally/survey" component={Survey} />
          <Route path="/sabritas/survey" component={SabritasSurvey} />
          <Route path="/pepsi/share" component={PepsiShare} />
          <Route path="/pepsi/combineable" component={PepsiCombineable} />
        </Switch>
      </Router>
    );
  }
}
