import React, { Component } from 'react'
import { CircularProgress } from '@material-ui/core'
const vatom = new window.VatomicFace.LocalVatom();

export default class Share extends Component {
    constructor() {
        super()
        this.state = {
            // vatom: {
            //     identifier: "Es una prueba",
            //     properties: {
            //         invitees: []
            //     },
            //     resources: {
            //         CardImage: 'https://cdn.blockv.io/blockv/publisher/blockv.vticketmx/att_rally_back.jpg?Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYmxvY2t2LmlvLyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE1ODE4MTQyNjd9LCJEb21haW4iOiJodHRwczovL2Nkbi5ibG9ja3YuaW8ifX1dfQ__&Signature=F6Itm~QJuvKaaIo1DcaKFplVCtMFqy7ApgUSpkjOZzA7PZ5XH49OCLDNMB7rRYMQ7HR-AyncvagzouFzCUp31YeP9ufwk9KYm-temBG~OGVoTT1GtmJ4LCMLYSw69Leo~ATW61GULwZQqr88NiyZ~od5TI2PcofVFR9qL8S1y637G3xxCeN3esZ3cKM3hbruQho9V~cSCVHCZqOrUyl0AdlV2qr8rwklmSs4GrogkX9SLkbmR47og59rmfpjMRuItYb8~W2eIgTBeq3LpBpmPgY2alk~afYiHvdkn2YSa~t0xozk4b-Ac-3ALYPCCStMNMgrI3wXdl1SL~~Mn6GrhA__&Key-Pair-Id=APKAI3WYOGPWWCDCS6PA',
            //     }
            // },
            // user: {
            //     displayName: "Sebastian Sanchez Alcala"
            // },
            isStarted: false,
            stepNumber: 1,
            number: ""
        }
    }

    componentDidMount() {
        vatom.initVatom().then((res) => {
            this.setState({ vatom: vatom, user: res.data.user })
        })
    }

    componentWillUnmount() {
        window.removeEventListener('touchstart', this.touchStart);
        window.removeEventListener('touchmove', this.preventTouch, { passive: false });
    }

    touchStart(e) {
        this.firstClientX = e.touches[0].clientX;
        this.firstClientY = e.touches[0].clientY;
    }

    preventTouch(e) {
        const minValue = 5; // threshold
        this.clientX = e.touches[0].clientX - this.firstClientX;
        this.clientY = e.touches[0].clientY - this.firstClientY;

        // Vertical scrolling does not work when you start swiping horizontally.
        if (Math.abs(this.clientX) > minValue) {
            e.preventDefault();
            e.returnValue = false;
            return false;
        }
    }
    setStart = () => {
        this.setState({
            stepNumber: 2
        })
    }

    sharePhoneNumber = (e) => {
        e.preventDefault()
        this.setState({ loading: true })
        const number = this.state.number;
        var vatom = this.state.vatom
        const url = "https://api.jalo.app/user/" + this.state.user.id + "/invite";
        if (!vatom.properties.invitees) {
            vatom.properties.invitees = []
        }
        if (!vatom.properties.invitees.includes(number)) {
            vatom.properties.invitees.push(number)
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ "phone": number }), // body data type must match "Content-Type" header
            }).then(response => {
                return response.json();
            }).then(async res => {
                console.log(res);
                var vatom = this.state.vatom
                var payload = {
                    "actionName": "Update",
                    "actionData": {
                        "this.id": vatom.identifier,
                        "properties": {
                            "invitees": vatom.properties.invitees
                        }
                    }
                }
                const update = await window.VatomicFace.VatomicBridge.sendRequest("vatom.performAction", payload)
                this.setState({ vatom, number: "", loading: false })
            });
        }
        else {
            this.setState({ loading: false })
        }

        if (vatom.properties.invitees.length == 5) {
            this.setState({
                stepNumber: 3, loading: false
            })
        }
    }

    getMedal = () => {
        var payload = {
            "actionName": "Redeem",
            "actionData": {
                "this.id": this.state.vatom.identifier,
                "new.owner.id": "ae9f9977-4723-41a2-b852-6b266e41543a"
            }
        }
        window.VatomicFace.VatomicBridge.sendRequest("vatom.performAction", payload).then(response => {
            if (typeof (response['errorCode']) != 'undefined') {
                console.log(response);
            }
            else {
                window.VatomicFace.VatomicBridge.sendRequest("vatom.view.close")
            }
        });
    }

    render() {

        return (
            this.state.vatom ?
                <div className="ATTSamsung" style={{ backgroundImage: `url(${this.state.vatom.resources.CardImage})` }}>
                    <div style={{ height: "100vh", width: "100vw" }}>
                        {this.state.stepNumber == 1 ?
                            <div>
                                <div className="ATT-Section">
                                    <div>
                                        <p className="Title-ATT">
                                            Invita a 5 amigos a descargar Jalo
                                        </p>
                                        <p>
                                            En este reto necesitarás el número de teléfono de 5 amigos. El ganador tendrá que proporcionar 5 números de teléfono válidos.
                                        </p>
                                        <button className="ATTSamsung-button" onClick={this.setStart} >Comenzar </button>
                                    </div>
                                </div>
                            </div>
                            : (this.state.stepNumber == 2 && (!this.state.vatom.properties.invitees || this.state.vatom.properties.invitees.length < 5) ?
                                <form onSubmit={this.sharePhoneNumber}>
                                    <div className="ATT-Section">
                                        <div>
                                            <p className="Title-ATT">
                                                Amigos invitados: {this.state.vatom.properties.invitees ? this.state.vatom.properties.invitees.length : 0}/5
                                        </p>
                                            <input className="input-form-control" name="phoneNumber" type="tel" placeholder="Número de teléfono" pattern="[0-9]{10,}" maxLength="10" required autoComplete="off" value={this.state.number} onChange={(event) => this.setState({ number: event.target.value })}></input>
                                            <button className="ATTSamsung-button" type="submit">Siguiente </button>
                                        </div>
                                    </div>
                                </form>
                                :
                                <div>
                                    <div className="ATT-Section">
                                        <div>
                                            <p className="Title-ATT">
                                                Amigos invitados: {this.state.vatom.properties.invitees ? this.state.vatom.properties.invitees.length : 0}/5
                                            </p>
                                            <p>
                                                <span style={{ display: 'block', fontWeight: 'bold' }}> ¡Muy bien!</span>
                                                Has completado este reto, has en click en el botón para recibir tu medalla
                                             </p>
                                            <button className="ATTSamsung-button" onClick={this.getMedal} >Completar Reto </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {this.state.loading ?
                            <div style={{ position: "relative", height: "100vh", width: "100vw", textAlign: "center", fontSize: 30 }}>
                                <CircularProgress style={{ color: "#a7329a", position: "absolute", margin: "auto", top: 0, bottom: 0, left: 0, right: 0 }} />
                            </div>
                            : null}
                    </div>
                </div>
                :
                <div style={{ position: "relative", height: "100vh", width: "100vw", textAlign: "center", fontSize: 30 }}>
                    <CircularProgress style={{ color: "#a7329a", position: "absolute", margin: "auto", top: 0, bottom: 0, left: 0, right: 0 }} />
                </div>
        )
    }
}