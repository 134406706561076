import React, { Component } from 'react';
import Slider from "react-slick";
import './style/Exa.css'
import BlockVFaceApi from '@blockv/faces'
import ReactPlayer from 'react-player'
import CircularProgress from '@material-ui/core/CircularProgress';


import Slide1 from './Slide1'
import Slide2 from './Slide2'

const vatom = new window.VatomicFace.LocalVatom()
export default class ExaTelcel extends Component {
    constructor() {
        super()
        this.state = {
            distance: 1,
            videoFinished: false,
            playVideo: false,
            // vatom: {
            //     identifier: "Sebas es un fregón",
            //     resources: {
            //         CardImage: 'https://cdn.blockv.io/blockv/publisher/blockv.vticketmx/exa_motorola_back.png?Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYmxvY2t2LmlvLyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE1NzAxMjQzMzh9LCJEb21haW4iOiJodHRwczovL2Nkbi5ibG9ja3YuaW8ifX1dfQ__&Signature=TZ9xaBMdLTgCoqx~qlMd20A6TGvHZ64xkVL~7igzrD90R0RP9E5iRr~vhSVxDy8ZMSWM0tUN5rMrlub06zGMoE9HIPdeCjMeUPJBk5-FAzuaRHCczXCdcs01LQ9hmIF5ke3DpYl-aZX0QcUUDPbuha-s7P~WvP8md9USQxkdymJWIYsseBuuX5JfddSCax7QhHX1k3rGEYwmcPvjgPUHxjGQKWc9WRfe-ExOzNc-QAdpzrJ4631UjkBCwOQzeV9LKJQ1UmGb1eW1KzdIdlL-jDkwshgROWwgFgxrmpIr9wc~Aer53FRhvqKxrIH13X~pWVwDdF41V1sVC7ZLoXV9zA__&Key-Pair-Id=APKAI3WYOGPWWCDCS6PA',
            //         Video: "https://cdn.blockv.io/blockv/publisher/blockv.vticketmx/MesMoto.mp4?Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYmxvY2t2LmlvLyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE1NzAxMjQzMzh9LCJEb21haW4iOiJodHRwczovL2Nkbi5ibG9ja3YuaW8ifX1dfQ__&Signature=TZ9xaBMdLTgCoqx~qlMd20A6TGvHZ64xkVL~7igzrD90R0RP9E5iRr~vhSVxDy8ZMSWM0tUN5rMrlub06zGMoE9HIPdeCjMeUPJBk5-FAzuaRHCczXCdcs01LQ9hmIF5ke3DpYl-aZX0QcUUDPbuha-s7P~WvP8md9USQxkdymJWIYsseBuuX5JfddSCax7QhHX1k3rGEYwmcPvjgPUHxjGQKWc9WRfe-ExOzNc-QAdpzrJ4631UjkBCwOQzeV9LKJQ1UmGb1eW1KzdIdlL-jDkwshgROWwgFgxrmpIr9wc~Aer53FRhvqKxrIH13X~pWVwDdF41V1sVC7ZLoXV9zA__&Key-Pair-Id=APKAI3WYOGPWWCDCS6PA"
            //     }
            // }
        }
        vatom.initVatom().then((res) => {
            console.log("paso")
            this.setState({ vatom: vatom, user: res.data.user })
        })
    }

    componentDidMount() {
        console.log("paso")
        // setInterval(() => {
        //     var today = new Date().getTime()
        //     var due_date = new Date(this.state.vatom.properties.event_date +"T23:59-06:00").getTime()
        //     var distance = due_date - today
        //     this.setState({ distance:distance })
        // },1000)
        window.addEventListener('touchstart', this.touchStart);
        window.addEventListener('touchmove', this.preventTouch, { passive: false });
    }

    handleRedemption() {
        var payload = {
            "actionName": "Redeem",
            "actionData": {
                "this.id": this.state.vatom.identifier,
                "new.owner.id": "ae9f9977-4723-41a2-b852-6b266e41543a"
            }
        }
        window.VatomicFace.VatomicBridge.sendRequest("vatom.performAction", payload).then(res => {
            console.log(res)
            if (typeof (res['errorCode']) != 'undefined') {
                alert("Ocurrió un problema, por favor inténtalo más tarde")
                window.VatomicFace.VatomicBridge.sendRequest("vatom.view.close")
            }
            else {
                window.VatomicFace.VatomicBridge.sendRequest("vatom.view.close")
            }
        })
    }


    componentWillUnmount() {
        window.removeEventListener('touchstart', this.touchStart);
        window.removeEventListener('touchmove', this.preventTouch, { passive: false });
    }

    touchStart(e) {
        this.firstClientX = e.touches[0].clientX;
        this.firstClientY = e.touches[0].clientY;
    }

    preventTouch(e) {
        const minValue = 5; // threshold

        this.clientX = e.touches[0].clientX - this.firstClientX;
        this.clientY = e.touches[0].clientY - this.firstClientY;

        // Vertical scrolling does not work when you start swiping horizontally.
        if (Math.abs(this.clientX) > minValue) {
            e.preventDefault();
            e.returnValue = false;
            return false;
        }
    }

    render() {
        const settings = {
            dots: true,
            infinite: false,
            arrows: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            this.state.vatom && this.state.distance > 0 ?
                !this.state.videoFinished ? (
                    <div style={{ height: "100vh", width: "100vw", backgroundImage: `url(${this.state.vatom.resources.CardImage || ""})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", margin: 0, display: "inline-block", textAlign: "center", position: "relative", backgroundSize: 'cover', backgroundPosition: 'bottom center', color: '#fff' }}>
                        <ReactPlayer
                            width='100%'
                            height='100%'
                            url={this.state.vatom.resources.Video}
                            playing={this.state.playVideo}
                            onEnded={() => { this.setState({ videoFinished: true }) }}
                        />
                        {!this.state.playVideo ? <button onClick={() => this.setState({ playVideo: true })} style={style}>
                            Reproducir Video
                        </button> : null}
                    </div>
                ) : (
                        <div style={{ height: "100vh", width: "100vw", backgroundImage: `url(${this.state.vatom.resources.CardImage || ""})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", margin: 0, display: "inline-block", textAlign: "center", position: "relative", backgroundSize: 'cover', backgroundPosition: 'bottom center', color: '#fff' }}>
                            <div style={{ padding: "5%" }}>
                                <h2>Busca la mesa de experiencia Motorola dentro del Centro Atención a clientes Telcel</h2>
                                <p style={{ fontSize: "2.7vh" }}>
                                    Acude con el representante Motorola y pide que escanee tu código QR para participar en la dinámica.
                                </p>
                                <img alt="qr acceso" style={{ display: "inline-block", width: "60%" }} src={"https://api.qrserver.com/v1/create-qr-code/?size=256x256&color=00E4A0&data=redeem://" + this.state.vatom.identifier + "://contest"} />
                            </div>
                        </div>
                    )
                :
                this.state.vatom && this.state.distance <= 0 ?
                    <div style={{ height: "100vh", width: "100vw", backgroundImage: `url(${this.state.vatom.resources.CardImage || ""})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", margin: 0, display: "inline-block", textAlign: "center", position: "relative" }}>
                        <div style={{ padding: "0 5%", marginTop: "40vh" }}>
                            <h1>¡Gracias por asistir!</h1>
                            <p>Espera pronto más boletos</p>
                            <p>Marca este concierto como completado y junta experiencias</p>
                            <a style={{ fontSize: "3vh", backgroundColor: this.state.vatom.btn_color, color: this.state.btn_text_color, width: "90%", padding: "10px 0", position: "absolute", margin: "auto", left: 0, right: 0, bottom: "10%", borderRadius: "30px", textDecoration: "none" }} onClick={this.handleRedemption.bind(this)}>¡Completar!</a>
                        </div>
                    </div>
                    : <CircularProgress style={{ color: "#00E4A0", position: "absolute", top: 0, left: 0, right: 0, bottom: 0, margin: "auto" }} />
        )
    }
}

const style = {
    position: "absolute", bottom: "25vh", left: "30vw", color: "#fff", backgroundColor: "#17a2b8", borderColor: "#17a2b8",
    fontWeight: 400,
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    padding: ".375rem .75rem",
    fontSize: "1rem",
    lineHeight: 1.5,
    borderRadius: ".25rem"
}