import React, { Component } from 'react'
import { CircularProgress } from '@material-ui/core'

const vatom = new window.VatomicFace.LocalVatom();

export default class Redeem extends Component {
    constructor() {
        super()
        this.state = {
            // vatom: {
            //     identifier: "Es una prueba",
            //     resources: {
            //         CardImage: 'https://cdn.blockv.io/blockv/publisher/blockv.vticketmx/att_rally_back.jpg?Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYmxvY2t2LmlvLyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE1ODE4MTQyNjd9LCJEb21haW4iOiJodHRwczovL2Nkbi5ibG9ja3YuaW8ifX1dfQ__&Signature=F6Itm~QJuvKaaIo1DcaKFplVCtMFqy7ApgUSpkjOZzA7PZ5XH49OCLDNMB7rRYMQ7HR-AyncvagzouFzCUp31YeP9ufwk9KYm-temBG~OGVoTT1GtmJ4LCMLYSw69Leo~ATW61GULwZQqr88NiyZ~od5TI2PcofVFR9qL8S1y637G3xxCeN3esZ3cKM3hbruQho9V~cSCVHCZqOrUyl0AdlV2qr8rwklmSs4GrogkX9SLkbmR47og59rmfpjMRuItYb8~W2eIgTBeq3LpBpmPgY2alk~afYiHvdkn2YSa~t0xozk4b-Ac-3ALYPCCStMNMgrI3wXdl1SL~~Mn6GrhA__&Key-Pair-Id=APKAI3WYOGPWWCDCS6PA',
            //     }
            // },
            // user: {
            //     displayName: "Sebastian Sanchez Alcala"
            // },
            isStarted: false
        }
    }

    componentDidMount() {
        vatom.initVatom().then((res) => {
            this.setState({ vatom: vatom, user: res.data.user })
        })
    }

    componentWillUnmount() {
        window.removeEventListener('touchstart', this.touchStart);
        window.removeEventListener('touchmove', this.preventTouch, { passive: false });
    }

    touchStart(e) {
        this.firstClientX = e.touches[0].clientX;
        this.firstClientY = e.touches[0].clientY;
    }

    preventTouch(e) {
        const minValue = 5; // threshold
        this.clientX = e.touches[0].clientX - this.firstClientX;
        this.clientY = e.touches[0].clientY - this.firstClientY;

        // Vertical scrolling does not work when you start swiping horizontally.
        if (Math.abs(this.clientX) > minValue) {
            e.preventDefault();
            e.returnValue = false;
            return false;
        }
    }
    setStart = () => {
        this.setState({
            isStarted: true
        })
    }

    render() {

        return (
            this.state.vatom ?
                <div className="ATTSamsung" style={{ backgroundImage: `url(${this.state.vatom.resources.CardImage})` }}>

                    <div style={{ height: "100vh", width: "100vw" }}>
                        {!this.state.isStarted ?

                            <div>
                                <div className="ATT-Section">
                                    <div>
                                        <p className="Title-ATT">
                                            Busca nuestra cancha AT&T
                                        </p>
                                        <p>
                                            Anota 10 canastas en el menor tiempo posible, al terminar haz click en el botón
                                         </p>
                                        <button className="ATTSamsung-button" onClick={this.setStart} >He terminado </button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div>
                                <div className="ATT-Section">
                                    <div>
                                        <p className="Title-ATT">
                                            Pídele al representante que escaneé tu código para validar tu reto
                                    </p>
                                        <img alt="qr acceso" style={{ display: "inline-block", width: "65%", marginTop: '10vh' }} src={"https://api.qrserver.com/v1/create-qr-code/?size=256x256&data=redeem://" + this.state.vatom.identifier} />

                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                :
                <div style={{ position: "relative", height: "100vh", width: "100vw", textAlign: "center", fontSize: 30 }}>
                    <CircularProgress style={{ color: "#a7329a", position: "absolute", margin: "auto", top: 0, bottom: 0, left: 0, right: 0 }} />
                </div>
        )
    }
}