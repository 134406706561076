import { CircularProgress } from '@material-ui/core';
import React, { Component } from 'react'

const vatom = new window.VatomicFace.LocalVatom();

export default class Combineable extends Component {
    constructor(){
        super()
        this.state={

        }
    }

    componentDidMount(){
        vatom.initVatom().then(async () => {
            const children = await vatom.getChildren()
            this.setState({children, vatom})
        })
    }
    render() {
        return (
            <div style={{flex:1, backgroundColor: 'black', minHeight:"100vh", minWidth:"100vw", display:"flex", alignItems:'center', justifyContent:"center"}}>
            {this.state.vatom?
            <div style={{flex:1, display:'flex', padding: 30, flexDirection:'column'}}>
                {this.state.vatom.properties.needed_parts > this.state.children.length ?
                <>
                <img src={this.state.vatom.resources.BaseImage} style={{maxWidth:"100%", objectFit: "contain", flex:1}}/>
                <div style={{color:'white', flex:1, alignItems:'center', justifyContent:'center', display:'flex', textAlign:'center'}}>
                    Demuestra que tienes el poder y arma el empaque de galletas Emperador®
                </div>
                </>
                :
                <>
                <img src={this.state.vatom.resources.FullImage} style={{maxWidth:"100%", objectFit: "contain", flex:1}}/>
                <div style={{color:'white', flex:1, alignItems:'center', justifyContent:'center', display:'flex', textAlign:'center'}}>
                    Escanea tu código para obtener el poderoso sabor de Emperador® 
                </div>
                <img src={"https://api.qrserver.com/v1/create-qr-code/?size=256x256&data=" + this.state.vatom.identifier} style={{marginTop:30}}/>
                </>
                }
            </div>    
            :
            <CircularProgress style={{color:"white"}}/>
        }
        </div>
        )
    }
}
