import React, { Component } from 'react'
import Slider from "react-slick";
import { CircularProgress } from '@material-ui/core'




const vatom = new window.VatomicFace.LocalVatom();


export default class Maxa extends Component {
    constructor() {
        super()
        this.state = {
            vatom: {
                identifier: "Es una prueba",
                resources: {
                    CardImage: 'https://maxa.jalo.app/static/media/maxa_bg.ecd6ae5f.png',
                }
            },
            user: {
                displayName: "Sebastian Sanchez Alcala"
            }
        }
    }


    componentDidMount() {
        vatom.initVatom().then((res) => {
            this.setState({ vatom: vatom, user: res.data.user })
        })
    }


    componentWillUnmount() {
        window.removeEventListener('touchstart', this.touchStart);
        window.removeEventListener('touchmove', this.preventTouch, { passive: false });
    }

    touchStart(e) {
        this.firstClientX = e.touches[0].clientX;
        this.firstClientY = e.touches[0].clientY;
    }

    preventTouch(e) {
        const minValue = 5; // threshold

        this.clientX = e.touches[0].clientX - this.firstClientX;
        this.clientY = e.touches[0].clientY - this.firstClientY;

        // Vertical scrolling does not work when you start swiping horizontally.
        if (Math.abs(this.clientX) > minValue) {
            e.preventDefault();
            e.returnValue = false;
            return false;
        }
    }

    render() {
        const settings = {
            dots: true,
            infinite: false,
            arrows: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };





        return (
            this.state.vatom ?
                <div className="maxa">
                    <div style={{ height: "100vh", width: "100vw", backgroundColor: "#f7f7f7" }}>
                        <Slider {...settings} adaptiveHeight={true}>

                            <div>
                                <div style={{
                                    height: "100vh", width: "100vw", margin: 0, display: "inline-block", textAlign: "center", position: "relative", backgroundPosition: "center",
                                    // backgroundImage: `url(${this.state.vatom.resources.CardImage})`,
                                    // backgroundRepeat: "no-repeat",
                                    backgroundColor: "#000", color: "#fff"
                                }}>

                                    <div style={{ paddingTop: "9vh" }}>

                                        <img src={require("../../../resources/maxa/images/logo.png")} style={{ width: "90px", display: "inline-block" }} />


                                        <p style={{ fontFamily: "Gilroy", fontStyle: "normal", fontSize: "4vh" }} >
                                            ESCANEA <br></br> ESTE CÓDIGO
                                    <br></br>
                                            <span style={{ fontSize: "3vh" }}>EN LA ENTRADA PARA VALIDAR TU TICKET</span>
                                        </p>
                                        {/* <h2>Este es tu pase doble</h2>
                                    <p>Presenta este código QR y una identificación en el portón Las Américas de Expo Guadalupe a las 16hrs. para acceder al evento</p>
                                    <p style={{ fontWeight: 'lighter', color: '#919191' }}>Este código es válido para ti y tu acompañante</p> */}
                                        <img alt="qr acceso" style={{ display: "inline-block", width: "60%" }} src={"https://api.qrserver.com/v1/create-qr-code/?bgcolor=a7329a&size=256x256&data=redeem://" + this.state.vatom.identifier} />
                                        <p style={{ fontFamily: "Gilroy", fontWeight: "bold", fontStyle: "normal" }}>{this.state.user.displayName}</p>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div style={{
                                    height: "100vh", width: "100vw", margin: 0, display: "inline-block", textAlign: "center", position: "relative", backgroundPosition: "center",
                                    // backgroundImage: `url(${this.state.vatom.resources.CardImage})`,
                                    backgroundRepeat: "no-repeat", backgroundColor: "#000", color: "#fff"
                                }}>

                                    <div style={{ paddingTop: "9vh" }}>

                                        <img src={require("../../../resources/maxa/images/logo.png")} style={{ width: "90px", display: "inline-block" }} />
                                        <div style={{ padding: "0px 35px" }}>
                                            <p style={{ fontFamily: "Gilroy", fontStyle: "normal", fontSize: "4vh" }} >
                                                Reenvía este boleto</p>

                                            <p style={{ fontFamily: "Gilroy", marginTop: "30%", fontStyle: "normal", }}>
                                                Puedes enviar este boleto a un familiar o amigo.
                                            </p>
                                            <p style={{ fontFamily: "Gilroy", fontStyle: "normal", }}>
                                                Da click en el botón superior para enviarlo <img style={{
                                                    display: "inline-block",
                                                    width: 30,
                                                    verticalAlign: "middle"
                                                }} src={require("../../../resources/maxa/images/send.png")} />
                                            </p>
                                        </div>
                                    </div>
                                    <img src={require("../../../resources/maxa/images/maco.png")} style={{ width: "70px", display: "inline-block", position: "absolute", bottom: "15%", left: 0, right: 0, margin: "auto" }} />
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
                :
                <div style={{ position: "relative", height: "100vh", width: "100vw", textAlign: "center", fontSize: 30 }}>
                    <CircularProgress style={{ color: "#a7329a", position: "absolute", margin: "auto", top: 0, bottom: 0, left: 0, right: 0 }} />
                </div>
        )
    }
}