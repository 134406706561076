import React, { Component } from 'react';
import Slider from "react-slick";
import BlockVFaceApi from '@blockv/faces'
import CircularProgress from '@material-ui/core/CircularProgress';
import './StarbucksCoupon.css';

const vatom = new window.VatomicFace.LocalVatom()
export default class StarbucksCoupon extends Component {
    constructor() {
        super();
        this.state = {
            distance: 1,

            // vatom: {
            //     identifier: "Sebas es un fregón",
            //     owner: "Sebas",
            //     resources: {
            //         CardImage: 'https://cdn.blockv.io/blockv/publisher/blockv.vticketmx/starbucks_card.png?Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYmxvY2t2LmlvLyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE1NzI3NDY4MzJ9LCJEb21haW4iOiJodHRwczovL2Nkbi5ibG9ja3YuaW8ifX1dfQ__&Signature=W9Es6g7-b25ec5xclwJwef0cKLSAjHu5C4cvfFKxmVF7iDHCEEHkropBFlOGdEosYYK6pj4ZjW-RvA2WcM2uXUlHR5VKppG6hNRaLmdd0UaGD7QxFB5AjM3Rs3y6EKXNnc5wJBYnQMsGyS0d0Uw-yAQBDrAB9dq01CozweBD02CVYl~~2PMvji1ZWyeROtN9V-hh9e4~d9VzCbNnAmqRhk1xLQzGIX8f5UdvDORPEMqLkw5uu1JP7jIh8Rs6PlMu1IHnapqIr0CfRbYqSKmlipBWr2jVfBJOXVnOtltkSjXHGJsXoGAvbUJNCQ3J6JB~oHgq4t2Oowqb7NH3fuYpCQ__&Key-Pair-Id=APKAI3WYOGPWWCDCS6PA',
            //         activatedImage: 'https://cdn.blockv.io/blockv/publisher/blockv.vticketmx/starbucks_logo.png?Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYmxvY2t2LmlvLyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE1NzI3NDY4MzJ9LCJEb21haW4iOiJodHRwczovL2Nkbi5ibG9ja3YuaW8ifX1dfQ__&Signature=W9Es6g7-b25ec5xclwJwef0cKLSAjHu5C4cvfFKxmVF7iDHCEEHkropBFlOGdEosYYK6pj4ZjW-RvA2WcM2uXUlHR5VKppG6hNRaLmdd0UaGD7QxFB5AjM3Rs3y6EKXNnc5wJBYnQMsGyS0d0Uw-yAQBDrAB9dq01CozweBD02CVYl~~2PMvji1ZWyeROtN9V-hh9e4~d9VzCbNnAmqRhk1xLQzGIX8f5UdvDORPEMqLkw5uu1JP7jIh8Rs6PlMu1IHnapqIr0CfRbYqSKmlipBWr2jVfBJOXVnOtltkSjXHGJsXoGAvbUJNCQ3J6JB~oHgq4t2Oowqb7NH3fuYpCQ__&Key-Pair-Id=APKAI3WYOGPWWCDCS6PA'
            //     },
            //     properties: {
            //         card_number: "",
            //         card_pin: ""
            //     }
            // }

        };

        vatom.initVatom().then((res) => {
            console.log("paso");
            console.log(vatom);
            if (!vatom.properties.card_number) {
                const url = "https://api.jalo.app/starbucks_coupon";
                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "vatom_id": vatom.identifier,
                        "user_id": vatom.properties.owner
                    })
                }).then(response => {
                    return response.json();
                }).then(async res => {
                    console.log(res)
                    if (res.Cupon) {
                        var payload = {
                            "actionName": "Update",
                            "actionData": {
                                "this.id": vatom.identifier,
                                "properties": {
                                    "card_number": res.Cupon.card_number,
                                    "card_pin": res.Cupon.pin
                                }
                            }
                        }

                        await window.VatomicFace.VatomicBridge.sendRequest("vatom.performAction", payload)
                        vatom.properties.card_number = res.Cupon.card_number
                        vatom.properties.card_pin = res.Cupon.pin
                        this.setState({ vatom })
                    }
                });
            }
            else{
                this.setState({ vatom })
            }

        });
    }

    handleActionClick(action) {
        console.log(action)
        this.setState({ action });
    }

    componentDidMount() {
        console.log("paso");
        var vatom = this.state.vatom
        window.addEventListener('touchstart', this.touchStart);
        window.addEventListener('touchmove', this.preventTouch, { passive: false });
    }

    handleRedemption() {
        var payload = {
            "actionName": "Redeem",
            "actionData": {
                "this.id": this.state.vatom.identifier,
                "new.owner.id": "ae9f9977-4723-41a2-b852-6b266e41543a"
            }
        }
        window.VatomicFace.VatomicBridge.sendRequest("vatom.performAction", payload).then(res => {
            console.log(res)
            if (typeof (res['errorCode']) != 'undefined') {
                alert("Ocurrió un problema, por favor inténtalo más tarde")
                window.VatomicFace.VatomicBridge.sendRequest("vatom.view.close")
            }
            else {
                window.VatomicFace.VatomicBridge.sendRequest("vatom.view.close")
            }
        })
    }


    componentWillUnmount() {
        window.removeEventListener('touchstart', this.touchStart);
        window.removeEventListener('touchmove', this.preventTouch, { passive: false });
    }

    touchStart(e) {
        this.firstClientX = e.touches[0].clientX;
        this.firstClientY = e.touches[0].clientY;
    }

    preventTouch(e) {
        const minValue = 5; // threshold

        this.clientX = e.touches[0].clientX - this.firstClientX;
        this.clientY = e.touches[0].clientY - this.firstClientY;

        // Vertical scrolling does not work when you start swiping horizontally.
        if (Math.abs(this.clientX) > minValue) {
            e.preventDefault();
            e.returnValue = false;
            return false;
        }
    }

    render() {
        const styles = {
            container: {
                width: '100vw',
                height: '100vh',
                textAlign: 'center'
            },
            borders: {
                width: '90vw',
                height: '90vh',
                borderStyle: 'solid',
                position: 'absolute',
                top: '5vh',
                left: '5vw',
                borderColor: '#b7aa81'
            },
            starbucksLogo: {
                width: '30vw',
                position: 'absolute',
                top: '20vh',
                left: 0,
                right: 0,
                margin: '0 auto',
                padding: '0 10vw'
            },
            cardInfo: {
                textAlign: 'left',
                position: 'absolute',
                bottom: '15vh',
                left: 0,
                right: 0,
                margin: '0 auto',
                // padding: '0 10vw'
            },
            cardStyle: {
                width: "100%",
            }
        }
        return (
            this.state.vatom ?
                <div style={styles.container} className="container" >
                    <div style={styles.borders} className="borders">
                        <img style={styles.starbucksLogo} src={this.state.vatom.resources.ActivatedImage} alt="starbucks logo" />
                        <div style={styles.cardInfo}>
                            <p style={{ position: "absolute", top: "8vh", left: "1vh" }}>
                                Codigo de la tarjeta:
                                <br></br>
                                {this.state.vatom.properties.card_number}
                            </p>
                            <p style={{ position: "absolute", bottom: "2vh", left: "1vh" }}>
                                PIN:
                                <br></br>
                                {this.state.vatom.properties.card_pin}
                            </p>
                            <img style={styles.cardStyle} src={this.state.vatom.resources.CardImage} />
                        </div>

                    </div>
                </div>
                : <CircularProgress style={{ color: "#00704a", position: "absolute", top: 0, left: 0, right: 0, bottom: 0, margin: "auto" }} />
        )
    }
}