import React, { Component } from 'react';

export default class Slide1 extends Component {
    constructor(){
        super()
        this.state = {
            
        }

    }

    componentDidMount(){
        var x = setInterval(() => {
            var today = new Date().getTime()
            var due_date = new Date(this.props.vatom.properties.start_clue+"-06:00").getTime()
            var distance = due_date - today
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            // console.log(due_date);
            // console.log(days);
            // console.log(hours);
            // console.log(minutes);
            // console.log(seconds);
            if (distance < 0) {
                clearInterval(x)
                this.setState({ distance: null })
            }
            else {
                this.setState({ distance: { days: days, hours: hours, minutes: minutes, seconds: seconds } })
            }
        }, 1000)
    }


    render(){
        return(
            typeof(this.props.vatom)!=='undefined'?
            <div style={{ height: "100vh", width: "100vw", backgroundImage: `url(${this.props.vatom.resources.CardImage1 || ""})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", margin: 0, display: "inline-block", textAlign: "center", position: "relative" }}>
                {this.state.distance ?
                <div>
                    <div style={{marginTop: "45vh", padding:"0 50px", fontSize:"3vh"}}>
                        {this.props.vatom.properties.description} 
                    </div>
                    <div style={{marginTop:"10vh", fontSize:"3vh"}}>
                        Falta
                        <span style={{display:"block", fontSize:"4vh"}}>{this.state.distance.days > 0 ? this.state.distance.days + " D " : null}{("0" + this.state.distance.hours).slice(-2) + ":" + ("0" + this.state.distance.minutes).slice(-2) + ":" + ("0" + this.state.distance.seconds).slice(-2)}</span>
                        <span style={{display:"block", color: '#919191', fontWeight:300, fontSize:"2.5vh"}}>para que puedas ver la pista</span>
                    </div>
                </div>
                :
                <div style={{marginTop:"55vh",fontSize:"4.5vh", padding:"0 50px"}}>
                    Esta pista<br></br>ha caducado
                    <p style={{fontSize:"3vh"}}>{this.props.vatom.properties.done_text}</p>
                </div>}
            </div>
            :null
        )
    }

}