import React, { Component } from 'react'
import { CircularProgress, Hidden } from '@material-ui/core'

const vatom = new window.VatomicFace.LocalVatom();
export default class Camera extends Component {
    constructor() {
        super()
        this.state = {
            // vatom: {
            //     properties: {
            //         photo: 'https://maxa.jalo.app/static/media/maxa_bg.ecd6ae5f.png',
            //         title: "Busca el banderín AT&T (Ojo, está dentro de la tienda)",
            //         body: "Tómate una foto con él y completa el reto"
            //     },
            //     resources: {
            //         CardImage: 'https://cdn.blockv.io/blockv/publisher/blockv.vticketmx/att_rally_back.jpg?Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYmxvY2t2LmlvLyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE1ODE4MTQyNjd9LCJEb21haW4iOiJodHRwczovL2Nkbi5ibG9ja3YuaW8ifX1dfQ__&Signature=F6Itm~QJuvKaaIo1DcaKFplVCtMFqy7ApgUSpkjOZzA7PZ5XH49OCLDNMB7rRYMQ7HR-AyncvagzouFzCUp31YeP9ufwk9KYm-temBG~OGVoTT1GtmJ4LCMLYSw69Leo~ATW61GULwZQqr88NiyZ~od5TI2PcofVFR9qL8S1y637G3xxCeN3esZ3cKM3hbruQho9V~cSCVHCZqOrUyl0AdlV2qr8rwklmSs4GrogkX9SLkbmR47og59rmfpjMRuItYb8~W2eIgTBeq3LpBpmPgY2alk~afYiHvdkn2YSa~t0xozk4b-Ac-3ALYPCCStMNMgrI3wXdl1SL~~Mn6GrhA__&Key-Pair-Id=APKAI3WYOGPWWCDCS6PA',
            //     }
            // },
            stepper: false
        }
    }
    componentDidMount() {
        window.addEventListener("getPhoto", this.subirFoto.bind(this))
        vatom.initVatom().then(async () => {
            if (vatom.properties.photo) {
                this.setState({ stepper: true })
            }
            await this.setState({ vatom: vatom })
        })
    }
    componentWillUnmount() {
        window.removeEventListener('touchstart', this.touchStart);
        window.removeEventListener('touchmove', this.preventTouch, { passive: false });
    }

    touchStart(e) {
        this.firstClientX = e.touches[0].clientX;
        this.firstClientY = e.touches[0].clientY;
    }

    preventTouch(e) {
        const minValue = 5; // threshold

        this.clientX = e.touches[0].clientX - this.firstClientX;
        this.clientY = e.touches[0].clientY - this.firstClientY;

        // Vertical scrolling does not work when you start swiping horizontally.
        if (Math.abs(this.clientX) > minValue) {
            e.preventDefault();
            e.returnValue = false;
            return false;
        }
    }

    handleRedeem() {
        var payload = {
            "actionName": "Redeem",
            "actionData": {
                "this.id": this.state.vatom.identifier,
                "new.owner.id": "ae9f9977-4723-41a2-b852-6b266e41543a"
            }
        }
        window.VatomicFace.VatomicBridge.sendRequest("vatom.performAction", payload).then(response => {
            if (typeof (response['errorCode']) != 'undefined') {
                console.log(response);
            }
            else {
                window.VatomicFace.VatomicBridge.sendRequest("vatom.view.close")
            }
        });
    }

    async subirFoto(image) {
        console.log(image);
        const url = "https://api.jalo.app/upload_image";
        let data = {
            "foto": image.detail,
            "vatom_id": this.state.vatom.identifier
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        }).then(response => {
            return response.json();
        }).then(async res => {
            console.log(res);
            var vatom = this.state.vatom
            var payload = {
                "actionName": "Update",
                "actionData": {
                    "this.id": vatom.identifier,
                    "properties": {
                        "photo": res.data.vatom.image_url
                    }
                }
            }

            vatom.properties.photo = image.detail;

            const update = await window.VatomicFace.VatomicBridge.sendRequest("vatom.performAction", payload)
            this.setState({
                distance: 1,
                vatom,
                stepper: true, loading: false
            });
        });
    }

    uploadPhoto() {
        //HERE U SHOULD UPLOAD PHOTO
    }

    handleStepper = () => {
        window.ReactNativeWebView.postMessage('{ "name": "openCamera" }')
        this.setState({ loading: true })
    }

    takePictureAgain() {
        window.ReactNativeWebView.postMessage('{ "name": "openCamera" }')
        this.setState({ loading: true })
    }

    render() {
        return (
            this.state.vatom ?
                <div className="ATTSamsung" style={{ backgroundImage: `url(${this.state.vatom.resources.CardImage})` }}>
                    {!this.state.stepper ?
                        <div className="ATT-Section">
                            <div>
                                <p className="Title-ATT" style={{ fontWeight: "bold" }}>
                                    {this.state.vatom.properties.title.replace("amp;","").replace("amp;","")}
                                </p>
                                <p>
                                    {this.state.vatom.properties.body.replace("amp;","").replace("amp;","")}
                                </p>
                                <button className="ATTSamsung-button" onClick={() => this.handleStepper(true)}> Tomar Foto </button>
                            </div>
                        </div> :
                        <div className="ATT-Section">
                            <img src={this.state.vatom.properties.photo} alt="Smiley face" style={{ width: "85%", height: "70%", marginTop: "60px" }} />
                            <div className="camera-buttons" >
                                <button className="ATTSamsung-button camera-button " style={{ position: "initial" }} onClick={this.takePictureAgain} > Volver a tomar foto </button>
                                <button className="ATTSamsung-button camera-button " style={{ position: "initial" }} onClick={this.handleRedeem.bind(this)} > Completar Reto </button>
                            </div>
                        </div>
                    }
                    <div>
                    </div>
                    {this.state.loading ?
                        <div style={{ position: "relative", height: "100vh", width: "100vw", textAlign: "center", fontSize: 30 }}>
                            <CircularProgress style={{ color: "#a7329a", position: "absolute", margin: "auto", top: 0, bottom: 0, left: 0, right: 0 }} />
                        </div>
                        : null}
                </div >
                :
                <div>
                    <CircularProgress style={{ color: "#999999", position: "absolute", margin: "auto", top: 0, bottom: 0, left: 0, right: 0 }} />
                </div>
        )
    }
}

