import React, { Component } from 'react';
import 'conekta-card-tokenizer'
import JaloAPI from '../../SDK/JaloAPI'

export default class Test extends Component {
    constructor() {
        super();
        this.state = {
            name: "",
            number: "",
            cvc: "",
            month: "",
            year: "",
            amount: 0,
            email: "",
            phone_number: "+525515103921",
            user_id: "77ca91ef-8c3a-4daf-b0ce-7881a1e52117"
        }
    }

    componentDidMount() {
        window.Conekta.setPublicKey("key_Af7BrovbKv9P5zD9yQGWGXw")
        window.Conekta.setLanguage("es")
    }

    handleInputChange(event) {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleSubmit(event) {
        event.preventDefault()
        // TODO: Validate each field
        const tokenParams = {
            card: {
                number: this.state.number,
                name: this.state.name,
                exp_year: this.state.year,
                exp_month: this.state.month,
                cvc: this.state.cvc
            }
        }
        // get brand
        // console.log(window.Conekta.card.getBrand(this.state.number))
        // last 4 digits
        // console.log(this.state.number.substr(this.state.number.length - 4))
        window.Conekta.Token.create(tokenParams, this.successCallback.bind(this), this.errorCallback.bind(this))

    }

    successCallback(data) {
        console.log("token", data)
        console.log("token", data.id)
        this.setState({ payment_enabled: true, token: data.id })
        // TODO: make customer
        // TODO: make charge
    }
    errorCallback(data) {
        console.log("error", data)
    }

    async handlePayment(event) {
        event.preventDefault()
        const mask = this.state.number[0] === 3 ? "XXXXX" : "XXXXXX"
        const card_number = this.state.number.substr(0, 6) + mask + this.state.number.substr(this.state.number.length - 4)
        const customer_id = await JaloAPI.getCustomer(this.state.user_id)
        if (customer_id.success) {
            console.log(customer_id.data[0])
            const paym = await JaloAPI.makePayment(customer_id.data[0].customer_id, "Prueba", this.state.amount * 100, "1")
                console.log(paym)
        }
        else {
            const res = await JaloAPI.createCustomer(this.state.name, this.state.email, this.state.phone_number, this.state.token, card_number)
            if (!res.success) {
                console.log(res.data)
            }
            else {
                const paym = await JaloAPI.makePayment(res.data.customer, "Prueba", this.state.amount * 100, "1")
                console.log(paym)
            }
        }


    }

    render() {
        return (
            <div>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <span className="card-errors"></span>
                    <div>
                        <label>
                            <span>Nombre del tarjetahabiente</span>
                            <input type="text" size="20" value={this.state.name} name="name" onChange={this.handleInputChange.bind(this)} />
                        </label>
                    </div>
                    <div>
                        <label>
                            <span>Email</span>
                            <input type="text" value={this.state.email} name="email" onChange={this.handleInputChange.bind(this)} />
                        </label>
                    </div>
                    <div>
                        <label>
                            <span>Número de tarjeta de crédito</span>
                            <input type="text" size="20" name="number" value={this.state.number} onChange={this.handleInputChange.bind(this)} />
                        </label>
                    </div>
                    <div>
                        <label>
                            <span>CVC</span>
                            <input type="text" size="4" name="cvc" value={this.state.cvc} onChange={this.handleInputChange.bind(this)} />
                        </label>
                    </div>
                    <div>
                        <label>
                            <span>Fecha de expiración (MM/AAAA)</span>
                            <input type="text" size="2" name="month" value={this.state.month} onChange={this.handleInputChange.bind(this)} />
                        </label>
                        <span>/</span>
                        <input type="text" size="4" name="year" value={this.state.year} onChange={this.handleInputChange.bind(this)} />
                    </div>
                    <button type="submit">Crear token</button>
                </form>
                {this.state.payment_enabled ?
                    <form onSubmit={this.handlePayment.bind(this)}>
                        <span>Monto de pago</span>
                        <input type="number" name="amount" value={this.state.amount} onChange={this.handleInputChange.bind(this)} />
                        <button type="submit">Realizar Pago</button>
                    </form>
                    : null}
            </div>
        )
    }
}