import React, { Component } from 'react';
import Slider from "react-slick";
import BlockVFaceApi from '@blockv/faces'
import CircularProgress from '@material-ui/core/CircularProgress';
import ATTClasicoVIP from './ATTClasicoVIP'
import ATTClasicoTickets from './ATTClasicoTickets'
import './ATT.css';
import PolygonButton from './PolygonButton'

const vatom = new window.VatomicFace.LocalVatom()
export default class ATTClasicoContest extends Component {
    constructor() {
        super();
        this.state = {
            distance: 1,
            // vatom: {
            //     identifier: "Sebas es un fregón",
            //     resources: {
            //         CardImage: 'https://cdn.blockv.io/blockv/publisher/blockv.vticketmx/att_clasico_fondo.jpg?Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYmxvY2t2LmlvLyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE1NzIwMjIyMDF9LCJEb21haW4iOiJodHRwczovL2Nkbi5ibG9ja3YuaW8ifX1dfQ__&Signature=inN8X0HeBcd3wVEyA-Ge5TEgEE08uYRR~VWjr96nyEx0bmYkag2iq59r2A-qugs4Wu8c-QlDIEHaYfX9KZRf419-S6V2XdjrcWUTsmf48RApKId8RCbszI-OYkE-CGILSmMw7PMP-18bqiJscKdYEP6WtYm3~1sey5bIPPhnQSGL4NDPSRBtNOkuhPzdhzEAFkI8Bqy6xR5cretONiM3VdULkJSLX9qXNYM92CN~-6xQAacWF8Bp6~m0weFXwRuzwbHKgiu5ilRFdCWCtT7tQrX0-8v26zJGxXVcyXqWGA4r4fTKSdclb2ewpVfbLNdAIfHWfi0fr8soLlAJHzEzOA__&Key-Pair-Id=APKAI3WYOGPWWCDCS6PA',
            //     },
            //     properties: {
            //         action1_text: "Gana 2 boletos para el América VS Cruz Azul",
            //         action2_text: "",
            //         action1: "ticket",
            //         action2: "",
            //         photo: "sebas",
            //         action: "",
            //         template_variation:"Prueba"
            //     }
            // }
        };

        vatom.initVatom().then((res) => {
            console.log("paso");
            console.log(vatom);
            this.setState({ vatom: vatom, user: res.data.user });
        });
    }

    handleActionClick(action) {
        console.log(action)
        this.setState({ action });
    }

    componentDidMount() {
        console.log("paso");
        // setInterval(() => {
        //     var today = new Date().getTime()
        //     var due_date = new Date(this.state.vatom.properties.event_date +"T23:59-06:00").getTime()
        //     var distance = due_date - today
        //     this.setState({ distance:distance })
        // },1000)
        window.addEventListener('touchstart', this.touchStart);
        window.addEventListener('touchmove', this.preventTouch, { passive: false });
    }

    handleRedemption() {
        var payload = {
            "actionName": "Redeem",
            "actionData": {
                "this.id": this.state.vatom.identifier,
                "new.owner.id": "ae9f9977-4723-41a2-b852-6b266e41543a"
            }
        }
        window.VatomicFace.VatomicBridge.sendRequest("vatom.performAction", payload).then(res => {
            console.log(res)
            if (typeof (res['errorCode']) != 'undefined') {
                alert("Ocurrió un problema, por favor inténtalo más tarde")
                window.VatomicFace.VatomicBridge.sendRequest("vatom.view.close")
            }
            else {
                window.VatomicFace.VatomicBridge.sendRequest("vatom.view.close")
            }
        })
    }


    componentWillUnmount() {
        window.removeEventListener('touchstart', this.touchStart);
        window.removeEventListener('touchmove', this.preventTouch, { passive: false });
    }

    touchStart(e) {
        this.firstClientX = e.touches[0].clientX;
        this.firstClientY = e.touches[0].clientY;
    }

    preventTouch(e) {
        const minValue = 5; // threshold

        this.clientX = e.touches[0].clientX - this.firstClientX;
        this.clientY = e.touches[0].clientY - this.firstClientY;

        // Vertical scrolling does not work when you start swiping horizontally.
        if (Math.abs(this.clientX) > minValue) {
            e.preventDefault();
            e.returnValue = false;
            return false;
        }
    }

    async goBackHere(image, action) {
        console.log(image + ' ' + action);
        const url = "https://api.jalo.app/upload_image";
        let data = {
            "foto": image,
            "vatom_id": this.state.vatom.identifier
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        }).then(response => {
            return response.json();
        }).then(async res => {
            console.log(res);
            var vatom = this.state.vatom
            var payload = {
                "actionName": "Update",
                "actionData": {
                    "this.id": vatom.identifier,
                    "properties": {
                        "photo": res.data.vatom.image_url,
                        "action": action
                    }
                }
            }

            vatom.properties.photo = image;
            vatom.properties.action = action;

            await window.VatomicFace.VatomicBridge.sendRequest("vatom.performAction", payload)
            this.setState({
                distance: 1,
                vatom
            });
            // resolve();
        });
    }

    render() {
        const styles = {
            felicidades: {
                fontSize: "6vh",
                textShadow: this.state.vatom ? this.state.vatom.properties.template_variation.indexOf("Malefica") !== -1 ? "3px 3px 0px #A7A7A7" : "3px 3px 0px #00ABE1" : "3px 3px 0px #00ABE1"
            }
        }
        if (this.state.vatom && this.state.distance > 0 && this.state.vatom.properties.photo == "") {
            return (
                <div style={{ height: "100vh", width: "100vw", backgroundImage: `url(${this.state.vatom.resources.CardImage || ""})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", margin: 0, display: "inline-block", textAlign: "center", position: "relative", backgroundSize: 'cover', backgroundPosition: 'bottom center', color: this.state.vatom.properties.template_variation.indexOf("Pue") == -1 ? '#fff' : '#000' }}>
                    {/* <img src={require('./img/logo_america.png')} style={{ width: 70, float: "left", marginLeft: 20, marginTop: 20 }} /> */}
                    {this.state.vatom.properties.template_variation.indexOf("Malefica") !== -1 ? <img src={require('./img/malefica_logo.png')} style={{ width: 70, float: "left", marginLeft: 20, marginTop: 20 }} /> : null}
                    {!this.state.action ?
                        <div style={{ padding: "5%", position: "absolute", top: "25%" }}>
                            <h2 style={styles.felicidades}>¡Felicidades!</h2>
                            {this.state.vatom && this.state.vatom.properties.template_variation.indexOf("Jersey") == -1 && this.state.vatom.properties.template_variation.indexOf("Navidad") == -1 ? 
                            <p style={{ fontSize: "2.7vh" }}>
                                Para recibir tus boletos indica una de las siguientes opciones:
                            </p>
                            :
                            <p style={{ fontSize: "2.7vh" }}>
                                Para recibir tu premio indica una de las siguientes opciones:
                            </p>
                            }
                            <div style={{ textAlign: "center" }}>
                                {this.state.vatom.properties.action1 ?
                                    <a onClick={this.handleActionClick.bind(this, this.state.vatom.properties.action1)}>
                                        <PolygonButton text={this.state.vatom.properties.action1_text} shadowColor={this.state.vatom.properties.template_variation.indexOf("Malefica") !== -1 ? '#A7A7A7' : null} />
                                    </a>
                                    : null}
                                {this.state.vatom.properties.action2 ?
                                    <a onClick={this.handleActionClick.bind(this, this.state.vatom.properties.action2)}>
                                        <PolygonButton onClick={this.handleActionClick.bind(this, this.state.vatom.properties.action2)} text={this.state.vatom.properties.action2_text} shadowColor={this.state.vatom.properties.template_variation.indexOf("Malefica") !== -1 ? '#A7A7A7' : null} style={{ marginTop: 30 }} />
                                    </a>
                                    : null}
                            </div>
                        </div>
                        : this.state.action == 'vip' ?
                            <ATTClasicoVIP vatom={this.state.vatom} handleBack={this.handleActionClick.bind(this, "")} goBackHere={this.goBackHere.bind(this)} action={this.state.vatom.properties.action2_text} />
                            : <ATTClasicoTickets vatom={this.state.vatom} handleBack={this.handleActionClick.bind(this, "")} goBackHere={this.goBackHere.bind(this)} action={this.state.vatom.properties.action1_text} />}
                </div>
            )
        } else if (this.state.vatom && this.state.distance > 0 && this.state.vatom.properties.photo != "") {
            const settings = {
                dots: true,
                infinite: false,
                arrows: false,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1
            };
            return (
                <Slider {...settings} adaptiveHeight={true}>
                    <div>
                        <div style={{ height: "100vh", width: "100vw", backgroundImage: `url(${this.state.vatom.resources.CardImage || ""})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", margin: 0, display: "inline-block", textAlign: "center", position: "relative", backgroundSize: 'cover', backgroundPosition: 'bottom center', color: this.state.vatom.properties.template_variation.indexOf("Pue") == -1 ? '#fff' : '#000' }}>
                            <img src={this.state.vatom.properties.photo} style={{ width: "100vw", height: "90vh", objectFit: "contain" }} />
                            <img alt="desliza" style={{ width: "70vw", position: 'absolute', bottom: "3vh", left: 0, right: 0, margin: "0 auto" }} src={require('../../../../resources/att/desliza_att.png')} />
                        </div>
                    </div>
                    <div>
                        <div style={{ height: "100vh", width: "100vw", backgroundImage: `url(${this.state.vatom.resources.CardImage || ""})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", margin: 0, display: "inline-block", textAlign: "center", position: "relative", backgroundSize: 'cover', backgroundPosition: 'bottom center', color: this.state.vatom.properties.template_variation.indexOf("Pue") == -1 ? '#fff' : '#000' }}>
                            <div style={{ padding: "5%" }}>
                                {this.state.vatom && this.state.vatom.properties.template_variation.indexOf("Jersey") == -1 && this.state.vatom.properties.template_variation.indexOf("Navidad") == -1 ? 
                                <h2 style={{ fontSize: "5vh" }}>¡Has ganado tus boletos!</h2>
                                :
                                <h2 style={{ fontSize: "5vh" }}>¡Has ganado tu premio!</h2>
                                }
                                {this.state.vatom && this.state.vatom.properties.template_variation.indexOf("Jersey") == -1  && this.state.vatom.properties.template_variation.indexOf("Navidad") == -1 ? 
                                <p style={{ fontSize: "3vh" }}>Acude con el representante y pide que escanée el código QR para que te entregue tus boletos</p>
                                :
                                <p style={{ fontSize: "3vh" }}>Acude con el representante y pide que escanée el código QR para que te entregue tu premio</p>
                                }
                                <img alt="qr acceso" style={{ display: "inline-block", width: "60%" }} src={"https://api.qrserver.com/v1/create-qr-code/?size=256x256&data=redeem://" + this.state.vatom.identifier + "://" + this.state.vatom.properties.action} />
                                <img alt="desliza" style={{ width: "70vw", position: 'absolute', bottom: "3vh", left: 0, right: 0, margin: "0 auto" }} src={require('../../../../resources/att/desliza_att.png')} />
                            </div>
                        </div>
                    </div>
                </Slider>
            )
        } else {
            return (<CircularProgress style={{ color: "#00abe1", position: "absolute", top: 0, left: 0, right: 0, bottom: 0, margin: "auto" }} />)
        }
    }
}