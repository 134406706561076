import React, { Component } from 'react';

export default class Slide2 extends Component {
    constructor() {
        super();
        this.state = {

        }
    }
    componentWillMount() {
    }

    countdown() {

    }

    render() {
        return (
            <div style={{ height: "100vh", width: "100vw", backgroundImage: `url(${this.props.vatom.resources.CardImage || ""})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", margin: 0, display: "inline-block", textAlign: "center", position: "relative", backgroundSize: 'cover', backgroundPosition: 'bottom center', color: '#fff' }}>
                <div style={{ padding: "5%" }}>
                    <h2>Busca la mesa de experiencia Motorola dentro del Centro Atención a clientes Telcel</h2>
                    <p style={{ fontSize: "2.7vh" }}>
                        Acude con el representante Motorola y pide que escaneé tu código QR para participar en la dinámica.
                    </p>
                    <img alt="qr acceso" style={{ display: "inline-block", width: "60%" }} src={"https://api.qrserver.com/v1/create-qr-code/?size=256x256&color=00E4A0&data=redeem://" + this.props.vatom.identifier + "://contest"} />
                    {/* <p>{this.props.user.displayName}</p> */}
                    {/* <img alt="desliza" style={{ width: "70vw", position: 'absolute', bottom: "3vh", left: 0, right: 0, margin: "0 auto" }} src={require('../../../resources/macro/desliza_motorola.png')} /> */}
                </div>
            </div>
        )
    }
}