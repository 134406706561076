var serverAddress = "http://0.0.0.0:8080";

export default class JaloAPI {
    static request(method, endpoint, payload) {
        const headers = {
            "Content-Type": "application/json"
        }

        if (method !== 'get' || method !== 'GET') {
            return fetch(serverAddress + endpoint, {
                method: method,
                headers: headers,
                body: JSON.stringify(payload)
            }).then((response) => {
                return response.json()
            }).then(result => {
                return result
            })
        }
        else {
            return fetch(serverAddress + endpoint, {
                method: method,
                headers: headers
            }).then((response) => {
                if (!response.ok) {
                    return response.status
                }
                return response.json()
            }).then(result => {
                return result
            })
        }
    }

    static createCustomer(name,email,phone_number,card_token, card_number){
        const payload={
            name : name,
            email : email,
            phone_number : phone_number,
            card_token : card_token,
            card_number : card_number
        }

        return this.request('POST','/create_customer',payload).then(res=>{
            return res;
        })
    }

    static makePayment(customer_id,product,unit_price,quantity){
        const payload={
            product : product,
            unit_price : unit_price,
            quantity : quantity,
            customer_id : customer_id
        }

        return this.request('POST','/charge',payload).then(res=>{
            return res;
        })
    }

    static getCustomer(user_id){
        return this.request('get','/user/' + user_id + '/customer_id').then(res=>{
            return res;
        })
    }

    static getCards(user_id){
        return this.request('get','/user/' + user_id + '/cards').then(res=>{
            return res;
        })
    }

    static deleteCardByIdx(user_id,card_index){
        return this.request('delete','/user/' + user_id + '/cards/' + card_index).then(res=>{
            return res;
        })
    }

}