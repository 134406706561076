import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';


export default class Slide2 extends Component {
    constructor() {
        super();
        this.state = {
            showModal: false
        }
        this.handleBackClick = this.handleBackClick.bind(this)
    }

    handleBackClick(event) {
        if (event.target.id === 'back') {
            this.setState({ showModal: true })
        }
        if (event.target.id === 'cartelModal' || event.target.id === 'closeModal') {
            this.setState({ showModal: false })
        }

    }

    render() {
        return (
            <div id="back" onClick={(event) => { this.handleBackClick(event) }} style={{ height: "100vh", width: "100vw", backgroundImage: `url(${this.props.vatom.resources.CardImage2 || ""})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition:'center', margin: 0, display: "inline-block", textAlign: "center", position: "relative" }}>
                <a href={"https://lamejor.com.mx/monterrey/eventos/el-macro-bodega-aurrera-378.html"} target="_blank" style={{ fontSize: "3vh", backgroundColor: '#FFEE3D', color: '#275DAF', width: "90%", padding: "10px 0", position: "absolute", margin: "auto", left: 0, right: 0, bottom: "10%", borderRadius: "30px", textDecoration: "none" }}>
                    Ver sitio del evento
                </a>
                {this.state.showModal ?
                    <div id="cartelModal" style={{ position: "absolute", top: 0, right: 0, left: 0, bottom: 0, backgroundColor: '#000' }}>
                        <CloseIcon id='closeModal' style={{position:"absolute", top:"5%", right:"5%", color:'#fff'}}/>
                        <img alt="cartel" style={{ position: "absolute", margin: "auto", top: 0, bottom: 0, width: "100%" }} src={this.props.vatom.resources.CardImage2} />
                    </div>
                    : null}
            </div>

        )
    }
}